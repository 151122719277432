<template>
	<div class="hidden lg:block fixed w-full top-6 left-0 px-4 mb-4 z-50">
		<div class="bg-white border transition-colors duration-500 text-dark dark:text-white border-white dark:border-dark bg-opacity-80 dark:bg-opacity-70 dark:bg-black backdrop-filter backdrop-blur-sm rounded-md w-full block overflow-hidden max-w-4xl mx-auto px-8 h-14">
			<div class="container px-0 h-full">
				<template v-if="!article">
					<div class="flex justify-between items-center h-full">
						<router-link :to="{ name: 'Home' }">
							<img src="/img/Renault_Group.svg" alt="Renault Group Logo" class="filter dark:invert transition-all duration-500" />
						</router-link>

						<ul class="flex justify-around h-full gap-4">
							<template v-for="menuItem of menuItems" :key="menuItem">
								<li class="flex-1 lg:flex-auto flex">
									<router-link :to="{ name: menuItem.destination }" class="router-link remove-tap-highlight relative">
										<span v-if="menuItem.name == 'Favoris'" class="pop-top absolute w-3 h-3 top-3 right-2 bg-green text-white dark:bg-white dark:text-green text-xs rounded-full flex items-center justify-center opacity-0"> + </span>
										<span class="hidden sm:block">
											{{ menuItem.name }}
										</span>
									</router-link>
								</li>
							</template>
						</ul>
						<div class="flex items-center gap-3">
							<DarkMode />
							<Bell />
						</div>
					</div>
				</template>

				<template v-else>
					<ul class="flex justify-between h-full items-center px-6">
						<li>
							<a href="#" @click.prevent="goBack()">
								<img src="/img/pictos/arrow.svg" class="filter dark:invert transition-all duration-500 transform -rotate-90" height="20" />
							</a>
						</li>
						<!-- <li v-if="article?.acces_iframe && !showPdf && article?.media_file?.extension == 'pdf' && article?.media_type != 2">
              <button @click.prevent="toggleShowPdf(true)" class="text-sm hover:underline">
                Accéder à l’article complet
              </button>
            </li> -->
						<li v-if="showPdf">
							<button @click.prevent="toggleShowPdf(false)" class="text-sm">Revenir au site web</button>
						</li>
						<li class="flex">
							<BtnLike :article="article" :size="'big'" />
						</li>
					</ul>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import BtnLike from "@/components/BtnLike.vue";
import Bell from "@/components/Bell.vue";
import DarkMode from "@/components/DarkMode.vue";

import { mapState } from "vuex";
import store from "@/store";

export default {
	name: "Navigation",
	emits: ["toggleShowPdf"],
	components: {
		BtnLike,
		Bell,
		DarkMode,
	},
	computed: {
		...mapState("post", ["post"]),
		article() {
			return this.$store.state.post.post;
		},
	},
	data() {
		return {
			showPdf: false,
			menuItems: [
				{
					name: "Accueil",
					destination: "Home",
				},
				{
					name: "Archives",
					destination: "Archives",
				},
				{
					name: "Favoris",
					destination: "Favoris",
				},
			],
		};
	},
	methods: {
		toggleShowPdf(val) {
			this.showPdf = val;
			this.$emit("toggleShowPdf", val);
		},
		goBack() {
			if (this.showPdf == true) {
				this.toggleShowPdf(false);
			} else {
				store.commit("post/hide");
			}
		},
		async share() {
			if (navigator.share) {
				await navigator.share({
					title: this.article.title,
					url: this.article.media_url,
				});
				return;
			}
		},
	},
};
</script>

<style scoped>
.router-link:not(.router-link-active) {
	@apply hover:opacity-100;
}

.router-link.router-link-active {
	@apply opacity-100;
}

.router-link {
	@apply flex flex-col flex-grow px-4;
}

@screen sm {
	.router-link {
		@apply flex-row;
	}
}
</style>
