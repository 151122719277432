<template>
	<div :class="size">
		<div
			@click.prevent="showPost"
			class="bg-white dark:bg-black dark:text-white
							transition-colors duration-500
							rounded-md overflow-hidden h-full flex flex-row md:flex-col relative cursor-pointer remove-tap-highlight">

			<div class="py-6 px-4 md:py-8 md:px-6 flex flex-col justify-between z-10 w-full h-full">
				<div class="flex justify-between">
					<template v-if="article.brand?.logo?.url">
						<img :src="article.brand.logo.url" class="filter dark:invert transition-all duration-500">
					</template>
					<p v-else class="mb-0 text-xs text-green dark:text-greenLight font-semibold transition-colors duration-500">
						{{ article.brand?.front_label ? article.brand.front_label : article.brand?.name }}
					</p>
					<BtnLike :article="article" size="small" />
				</div>
				<h2 class="my-4 leading-super-snug line-clamp sm:line-clamp-3 lg:line-clamp-2" :class="layout == 2 ? 'line-clamp-4' : 'line-clamp-2'">
					{{ article.title }}
				</h2>
				<p class="flex-none text-xs text-greyDarken max-w-full">
					{{ article.source }}
					<span class="w-0.5 h-0.5 mx-1 md:mx-2 mb-0.5 bg-lightGreen rounded-full inline-block transition-color duration-500"></span>
					{{ dateInterval }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import BtnLike from "@/components/BtnLike.vue";
import store from "@/store";

import moment from 'moment';

export default {
	props: ["article", "layout"],
	components: {
		BtnLike,
	},
	computed: {
		dateInterval() {
			// "2023-10-27 04:58:24"
			moment.locale('fr');
			return moment(this.article.publication_date,"YYYY-MM-DD hh:mm:ss").fromNow()
		},
		size() {
			let size = 'col-span-2 md:col-span-1 lg:col-span-1'
			if(this.layout == "2") {
				// Mobile
				size = 'col-span-1 md:col-span-1 lg:col-span-1'
			} else if(this.layout == "0") {
				// DESKTOP
				size = 'col-span-2 md:col-span-1 lg:col-span-2'
			}

			// console.log(this.article)
			if(this.article.force_small_size) {
				size = size.replace("col-span-2", "col-span-1")
			}
			return size
		},
	},
	methods: {
		showPost() {
			this.$router.push({name:this.$route.name, hash:'#post'})
			store.commit("post/show", this.article);
		},
	},
};
</script>

<style>
.line-clamp {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	word-break: normal;
}

.line-clamp-2 {
	-webkit-line-clamp: 2;
}

.line-clamp-4 {
	-webkit-line-clamp: 4;
}

@screen sm {
	.sm\:line-clamp-3 {
		-webkit-line-clamp: 3;
	}
}

@screen lg {
	.lg\:line-clamp-2 {
		-webkit-line-clamp: 2;
	}
}
</style>
