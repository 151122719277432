<template>
  <div class="lg:hidden w-full h-10">
    <div class="container mx-auto px-4 flex justify-between items-center h-full">
      <router-link :to="{ name: 'Home' }">
        <img src="/img/Renault_Group.svg" alt="Renault Group Logo" class="filter invert">
      </router-link>
      <div class="flex items-center gap-2">
        <DarkMode mode="light"/>
        <Bell mode="light"/>
      </div>
    </div>
  </div>
</template>

<script>
import Bell from '@/components/Bell.vue'
import DarkMode from '@/components/DarkMode.vue'


export default {
  name: 'HomeHeader',
  components: {
    DarkMode,
    Bell,
  },
  setup() {
  },
}
</script>
