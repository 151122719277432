import UserService from '../services/user.service';
import moment from 'moment';

export const notification = {
  namespaced: true,
  state: {
    lastUpdate: localStorage.getItem('last_update'),
    hasNotification: false,
    showNotif: false,
  },
  actions: {
    getLastUpdate({ commit }) {
      UserService.getLastUpdate()
      .then(res => {
        if (res.status !== 200) {
          const error = new Error(res.statusText);
          error.json = res;
          throw error.json.statusText;
        }
        let date = moment(res.data['last-update']).format('DD/MM/YYYY à hh:mm')
        commit('updateLastUpdate', date);
      })
      .catch(err => {
        if (err.json) {
          return err.json.then(json => {
            return Promise.reject(json.message);

          });
        }
      })
    },
  },
  mutations: {
    updateLastUpdate(state, date) {
      if(state.lastUpdate != date) {
        state.hasNotification = true
        localStorage.setItem('last_update', date);
        state.lastUpdate = date;
      }
    },
    showNotification(state) {
      state.showNotif = true;
      state.hasNotification = false
      setTimeout(function () {
        state.showNotif = false;
      }, 2500)
    },
    hideNotification(state) {
      state.showNotif = false;
    }
  }
}
