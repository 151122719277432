<template>
	<div class="pb-20 lg:pb-10 flex flex-col items-center justify-center min-h-full scrollable" v-if="  articles !== null " >
        <HomeHeader  v-if="!featuredArticle" />
		<div class="lg:flex items-stretch w-full bg-cover bg-center bg-no-repeat overflow-hidden"  v-if="featuredArticle" :class="{'bg-black' : featuredArticle && featuredArticle.image?.url == null, 'filter grayscale' : featuredArticle && !featuredArticle.image  }" :style="isMobile ? `background-image:url('${featuredArticle && featuredArticle.image ? featuredArticle.image?.url : '/img/blurry3.jpg'}')` : ''"  >
            <HomeHeader  />
			<div class="lg:w-6/12" :class="{ 'bg-black': !isMobile }" v-if="featuredArticle">
				
				<template v-if="headTopNews">
					<template v-if="featuredArticle">
						<FeaturedPost :article="featuredArticle" />
					</template>
					<template v-else>
						<LoadingFeaturedPost />
					</template>
				</template>
			</div>
			<div :style="!isMobile ? `background-image:url('${featuredArticle && featuredArticle.image ? featuredArticle.image?.url : '/img/blurry3.jpg'}');` : ''" class="bg-cover bg-center bg-no-repeat overflow-hidden lg:w-6/12" :class="{'filter grayscale': featuredArticle && !featuredArticle.image }"></div>
		</div>

		<!-- CAROUSSEL TOP NEWS -->
		<div class="mt-20 mb-24 overflow-hidden w-full" :class="headTopNews ? '' : 'lg:mt-32'" v-if="topArticles.length > 0">
			<h1 class="container text-center w-full text-5xl mb-14 lg:mb-20 dark:text-white transition-colors duration-500 font-semibold">Découvrez {{ topArticles && topArticles.length == 1 ? "la" : "les" }}<br />top news</h1>
			<div class="max-w-xs mx-auto px-4">
				<swiper :pagination="true" :mousewheel="false" :initialSlide="initialSlide" class="swiper topnews-swiper">
					<template v-if="topArticles && topArticles.length">
						<swiper-slide v-for="topArticle of topArticles" :key="topArticle">
							<CardPostBig :article="topArticle" ratio="ratio-1/1" />
						</swiper-slide>
					</template>
					<template v-else-if="!errorTopArticles">
						<swiper-slide v-for="n of 6" :key="n">
							<LoadingCardPostBig ratio="ratio-1/1" />
						</swiper-slide>
					</template>
				</swiper>
				<!-- ERROR MESSAGE -->
				<p v-if="errorTopArticles" class="mt-8 text-center dark:text-white transition-colors duration-500">
					{{ errorTopArticles }}
				</p>
			</div>
		</div>

		<!-- POSTS NORMAUX -->
		<div class="w-full pb-10  overflow-hidden ">
			<!-- ERROR MESSAGE -->
			<p v-if="errorArticles" class="container text-center mt-8 dark:text-white transition-colors duration-500">
				{{ errorArticles }}
			</p>


			<div class="container flex justify-center  pt-2 dark:bg-dark bg-light container-filter" :class="{ 'lg:pt-28' :  topArticles.length == 0  && !featuredArticle, 'lg:pt-0' :  topArticles.length > 0  || featuredArticle }" :style="isMobile ? 'height: 56px;' : ''">
				<button class="text-white text-sm rounded-md px-24 py-4 bg-mineral  whitespace-nowrap flex items-center justify-center gap-2 lg:w-max w-full font-semibold  filter-sticky" @click="openFilter"  :class="{'fixed top-0 z-40 fixed-style': fixed && isMobile}" >
					<img src="/img/pictos/filter.svg" width="18" class="filter transition-all duration-500 transform rotate-180" />
					Filtrer l'actualité
				</button>
			</div>

            
            
			<div v-for="(article, key) in articles" :key="key" >
                <template v-if="hasArticles(article)">
                    <template v-if="filtersList[key].length > 0 || (filtersList['Marques'].length == 0 && filtersList['Secteurs'].length == 0 && filtersList['Corporate'].length == 0 )  ">
                        <div class="container pt-10">
                            <span class="dark:text-white lg:text-32 text-24 font-semibold">{{ key == 'Secteurs' ? 'Thématiques': (key == 'Marques' ? 'Marques et entités' : key)  }}  </span>
                        </div>
                        <div v-for="brand in article.brands" :key="brand.id">
                            <div class="container lg:pb-10 pb-6 pt-6 lg:pt-10" v-if="(filtersList[key].includes(brand.slug) || filtersList[key].length == 0) && brand.articles && brand.articles.length > 0">
                                <div class="dark:text-white text-sm pb-6 font-light flex justify-between">
                                    <div>
                                        {{ brand.front_label == null ? brand.name : brand.front_label }} <span v-if="isMobile">({{ brand.articles.length }})</span>
                                    </div>
                                    <span v-if="!isMobile">{{ brand.articles.length }} {{ brand.articles.length > 1 ? "articles" : "article" }}</span>
                                </div>
                                <div class="h-px w-full border-b border-grey2"></div>
                            </div>
                            <transition name="vue-accordion">
                                <template class="container grid grid-flow-row-dense grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 md:gap-6 lg:gap-8 xl:gap-10">
                                    <template v-for="art in brand.articles" :key="art.id">
                                        <CardPost :article="art" :layout="art.layout" v-if="filtersList[key].includes(brand.slug) || filtersList[key].length == 0" />
                                    </template>
                                </template>
                            </transition>
                        </div>
                    </template>
                </template>
			</div>
            <div class="container grid grid-flow-row-dense grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 md:gap-6 lg:gap-8 xl:gap-10">
                <template v-if="loading">
                    <LoadingCardPost v-for="n of 6" :key="n"/>
                </template>
            </div>
		</div>

		<cp-overlay :show="showFiltersOverlay" :sidebarInDesktop="true" @close-modal="closeFiltersModal">
			<template v-slot:content>
				<cp-filters-and-sorters :tags="articles" @apply-filters="filterChose" :filters-list="filtersList" @close-filters-modal="closeFiltersModal" />
			</template>
		</cp-overlay>
	</div>
    <div v-if="articles == null" class="">
        <HomeHeader  class="bg-dark" />

        <div class=" flex  justify-center items-center min-h-full flex-col gap-10 text-center h-screen">
            <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 12C0 5.37259 5.37258 0 12 0H46C52.6274 0 58 5.37258 58 12V46C58 52.6274 52.6274 58 46 58H12C5.37259 58 0 52.6274 0 46V12ZM12 8C9.79086 8 8 9.79086 8 12V46C8 48.2091 9.79086 50 12 50H46C48.2091 50 50 48.2091 50 46V12C50 9.79086 48.2091 8 46 8H12Z" fill="#978B7F" class="svg-elem-1"></path>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 82C0 75.3726 5.37258 70 12 70H46C52.6274 70 58 75.3726 58 82V116C58 122.627 52.6274 128 46 128H12C5.37259 128 0 122.627 0 116V82ZM12 78C9.79086 78 8 79.7909 8 82V116C8 118.209 9.79086 120 12 120H46C48.2091 120 50 118.209 50 116V82C50 79.7909 48.2091 78 46 78H12Z" fill="#978B7F" class="svg-elem-2"></path>
<path fill-rule="evenodd" clip-rule="evenodd" d="M70 12C70 5.37259 75.3726 0 82 0H116C122.627 0 128 5.37258 128 12V46C128 52.6274 122.627 58 116 58H82C75.3726 58 70 52.6274 70 46V12ZM82 8C79.7909 8 78 9.79086 78 12V46C78 48.2091 79.7909 50 82 50H116C118.209 50 120 48.2091 120 46V12C120 9.79086 118.209 8 116 8H82Z" fill="#978B7F" class="svg-elem-3"></path>
<path fill-rule="evenodd" clip-rule="evenodd" d="M70 82C70 75.3726 75.3726 70 82 70H116C122.627 70 128 75.3726 128 82V116C128 122.627 122.627 128 116 128H82C75.3726 128 70 122.627 70 116V82ZM82 78C79.7909 78 78 79.7909 78 82V116C78 118.209 79.7909 120 82 120H116C118.209 120 120 118.209 120 116V82C120 79.7909 118.209 78 116 78H82Z" fill="#978B7F" class="svg-elem-4"></path>
</svg>



        <div class="font-bold dark:text-white text-24 ">La revue de presse du jour se prépare.</div>

        <div class="font-light dark:text-white text-24">En attendant, vous pouvez consulter les actualités des jours précédents dans la section Archives</div>
        </div>

        
    </div>
</template>

<script>
import CardPost from "@/components/CardPost.vue";
import CardPostBig from "@/components/CardPostBig.vue";
import FeaturedPost from "@/components/FeaturedPost.vue";
import HomeHeader from "@/components/HomeHeader.vue";
import LoadingCardPostBig from "@/components/loading/CardPostBig.vue";
import LoadingFeaturedPost from "@/components/loading/FeaturedPost.vue";
import LoadingCardPost from '@/components/loading/CardPost.vue'
import CpOverlay from "../components/CpOverlay.vue";
import CpFiltersAndSorters from "../components/CpFiltersAndSorters.vue";
import { onMounted, ref, onUnmounted } from "vue";

import { Swiper, SwiperSlide } from "swiper/vue";
// import Swiper core and required modules
import SwiperCore, { Mousewheel, Pagination } from "swiper/core";
// install Swiper modules
SwiperCore.use([Mousewheel, Pagination]);

import UserService from "../services/user.service";

export default {
	components: {
		HomeHeader,
		CardPost,
		LoadingCardPostBig,
		LoadingFeaturedPost,
        LoadingCardPost,
		CardPostBig,
		FeaturedPost,
		Swiper,
		SwiperSlide,
		CpOverlay,
		CpFiltersAndSorters,
	},
	computed: {
		initialSlide() {
			if (window.innerWidth > 1024) {
				return 1;
			}
			return 0;
		},
	},
	name: "Home",
	setup() {
		const articles = ref([]);
		const errorTags = ref(null);
		const topArticles = ref([]);
		const featuredArticle = ref(null);
		const loading = ref(true);
		const errorArticles = ref(null);
		const errorTopArticles = ref(null);
		const showFiltersOverlay = ref(false);
        const isVisible = ref(true)
        // const lastScrollPosition = ref(0)
        const elementPosition = ref(0)
        const lastScrollTop = ref(0)
        const fixed = ref(false)
		const isMobile = ref(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
		const filtersList = ref({
			Marques: [],
			Corporate: [],
			Secteurs: [],
		});
		const headTopNews = ref(localStorage.getItem("headTopNews") == 0 ? false : true);

		const openFilter = () => {
			showFiltersOverlay.value = true;
		};

		const closeFiltersModal = () => {
			showFiltersOverlay.value = false;
		};
        const hasArticles = (value) => {
            return value.brands.some(brand => brand.articles  && brand.articles.length > 0);
        }


        const hasNoArticles = (data) => {
            for (const key in data) {
                const section = data[key];
                if (section.brands && Array.isArray(section.brands)) {
                    for (const brand of section.brands) {
                        if (brand.articles && brand.articles.length > 0) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }

		const fetchArticles = () => {
			loading.value = true;
			UserService.getPosts(0)
				.then((res) => {
					if (res.status !== 200) {
						const error = new Error(res.statusText);
						error.json = res;
						throw error.json.statusText;
					}
                    console.log(hasNoArticles(res.data))
                    if(hasNoArticles(res.data) == true  ) {
					articles.value = res.data;
                    }
                    else {
                        articles.value = null
                    }
				})
				.catch((err) => {
					errorArticles.value = err;
					if (err.json) {
						return err.json.then((json) => {
							errorArticles.value.message = json.message;
						});
					}
				})
				.then(() => {
					loading.value = false;
				});
		};

		const filterChose = (value) => {
			filtersList.value = value;
		};

		const fetchTopArticles = () => {
			UserService.getTopPosts()
				.then((res) => {
					if (res.status !== 200) {
						const error = new Error(res.statusText);
						error.json = res;
						throw error.json.statusText;
					}
					if (res.data.data.length == 0) {
						errorTopArticles.value = "Top News à la prochaine mise à jour";
					} else {
						topArticles.value = res.data.data;
						featuredArticle.value = topArticles.value.find((el) => el.head_top_news == true);

						if (!featuredArticle.value) {
							headTopNews.value = false;
							localStorage.setItem("headTopNews", 0);
						} else {
							localStorage.setItem("headTopNews", 1);
							let indexFeatured = topArticles.value.findIndex((el) => el.head_top_news == true);
							topArticles.value.splice(indexFeatured, 1);
						}
					}
				})
				.catch((err) => {
					errorTopArticles.value = err;
					if (err.json) {
						return err.json.then((json) => {
							errorTopArticles.value.message = json.message;
						});
					}
				});
		};
        const filterSticky = () => {
            elementPosition.value = document.querySelector('.filter-sticky').getBoundingClientRect().top;
            var st = window.scrollY || document.documentElement.scrollTop; 
            if (st > lastScrollTop.value ) {
               if(elementPosition.value <= 0 && fixed.value == false ) {
                    fixed.value = true
               }
            } else if (st < lastScrollTop.value) {
               
                if(st <= document.querySelector('.container-filter').offsetTop && fixed.value == true ) {
                    fixed.value = false
               }
            } 
            lastScrollTop.value = st <= 0 ? 0 : st;

        }

		onMounted(() => {
			fetchArticles();
			fetchTopArticles();
            setTimeout( () => {
                window.addEventListener('scroll', filterSticky);
            }, 500)
		});
        onUnmounted(() => {
            window.removeEventListener('scroll', filterSticky);
        })
		return {
			articles,
			topArticles,
			featuredArticle,
			loading,
			errorArticles,
			errorTopArticles,
			headTopNews,
			errorTags,
			fetchArticles,
			openFilter,
			showFiltersOverlay,
			closeFiltersModal,
			CpFiltersAndSorters,
			filterChose,
			isMobile,
			filtersList,
            hasArticles,
            filterSticky,
            isVisible, 
            fixed,
		};
	},
   

	updated() {},
	methods: {},
};
</script>

<style>
/* TOPNEWS SWIPER */

.topnews-swiper .swiper-wrapper {
	@apply -ml-4 lg:-ml-8;
}

.topnews-swiper.swiper.swiper-container {
	@apply pb-12 lg:pb-16;
}

.topnews-swiper .swiper-slide {
	@apply transform scale-95 lg:scale-90 transition-all duration-300;
}

.topnews-swiper .swiper-slide.swiper-slide-active {
	@apply transform scale-105 lg:scale-110 mx-4 lg:mx-8;
}

.topnews-swiper .swiper-slide {
	@apply lg:opacity-0;
}

.topnews-swiper .swiper-slide.swiper-slide-active,
.topnews-swiper .swiper-slide.swiper-slide-prev,
.topnews-swiper .swiper-slide.swiper-slide-next {
	@apply opacity-100;
}

/* TAGS SWIPER */

.tags-swiper.swiper-container {
	@apply pb-0;
}
</style>
