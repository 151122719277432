<template>
	<div class="w-full bg-white dark:bg-black dark:text-white transition-colors duration-500 rounded-md overflow-hidden relative cursor-pointer remove-tap-highlight">
			<div class="bg-green bg-cover bg-center bg-no-repeat flex-shrink-0 relative"
					:class="ratio ? ratio : 'ratio-16/9'">
			<div class="bg-white text-dark text-sm rounded-full px-4 py-2
									absolute top-4 left-4 animate-pulse h-9 w-28">
			</div>
		</div>

		<div class="py-6 flex flex-col justify-between z-10 px-4">
			<div class="rounded-xl bg-green bg-opacity-50 h-3.5 w-16 animate-pulse"></div>
			<div class="mt-6 animate-pulse rounded-xl bg-green bg-opacity-50 h-4 mb-2"></div>
			<div class="mb-6 animate-pulse rounded-xl bg-green bg-opacity-50 h-4 w-40"></div>
			<div class="animate-pulse rounded-xl bg-green bg-opacity-50 h-3 w-12"></div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["ratio"],
}
</script>
