import axios from 'axios';
import authHeader from './auth-header';

class UserService {
  token(currentToken) {
    return axios.patch(process.env.VUE_APP_API_URL + 'fcm-token', {
      headers: authHeader(),
      token: currentToken
    })
  }
  getLastUpdate() {
    return axios.get(process.env.VUE_APP_API_URL + 'last-update', {
      headers: authHeader()
    })
  }
  getSettings() {
    return axios.get(process.env.VUE_APP_API_URL + 'settings', {
      headers: authHeader()
    })
  }
  getPosts(start_date, nextPageUrl, params) {
    let route = nextPageUrl;
    if(!route) {
      route = process.env.VUE_APP_API_URL + `articles?archives=${start_date}`;
      if(params) {
        route = route + params
      }
    }
    return axios.get(route, {
      headers: authHeader()
    })
  }
  getPostsByBrand(brand, nextPageUrl, params) {
    let route = nextPageUrl;
    if(!route) {
      route = process.env.VUE_APP_API_URL + 'brand/' + brand;
      if(params) {
        route = route + params
      }
    }
    return axios.get(route, {
      headers: authHeader()
    });
  }
  getSinglePost(id) {
    return axios.get(process.env.VUE_APP_API_URL + 'article/' + id, {
      headers: authHeader()
    });
  }
  getTopPosts() {
    return axios.get(process.env.VUE_APP_API_URL + 'articles/top-news', {
      headers: authHeader()
    });
  }
  getBrands() {
    return axios.get(process.env.VUE_APP_API_URL + 'brands?brands=1', {
      headers: authHeader()
    });
  }
  getTags() {
    return axios.get(process.env.VUE_APP_API_URL + 'brands?tags=1', {
      headers: authHeader()
    });
  }

  getTagsByDate(start_date, end_date) {
    return axios.get(process.env.VUE_APP_API_URL + `brands?start_date=${start_date}&end_date=${end_date}`, {
      headers: authHeader()
    });
  }
  getSingleBrand(brand) {
    return axios.get(process.env.VUE_APP_API_URL + 'brand-detail/' + brand, {
      headers: authHeader()
    });
  }

  rephraseArticle(id) {
    return axios.get(process.env.VUE_APP_API_URL + 'openai/rephrase/' + id, {
      headers: authHeader()
    });
  }


  postFeedBack(id, review) {
    return axios.post(process.env.VUE_APP_API_URL + `article/${id}/review`, {
        review: review,
      },{ headers: authHeader() });
  }
  getLiked() {
    return axios.get(process.env.VUE_APP_API_URL + 'article-liked', {
      headers: authHeader()
    });
  }
  like(id) {
    return axios.get(process.env.VUE_APP_API_URL + 'article-like/' + id, {
      headers: authHeader()
    });
  }
  dislike(id) {
    return axios.get(process.env.VUE_APP_API_URL + 'article-unlike/' + id, {
      headers: authHeader()
    });
  }
}
export default new UserService();
