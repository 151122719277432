<template>
  <button @click.stop.prevent="toggleLike()"
          class="remove-tap-highlight relative small transition-all duration-500 flex-shrink-0"
          :class="size == 'big' ? 'w-5 h-5' : 'w-4 h-4'">

    <div class="heart"
         :class="{
          'is_animating' : liked,
          'to_the_end' : initial_liked
        }">
    </div>
    <transition name="bounce" mode="out-in">
      <img src="../../public/img/pictos/heart-red.svg" class="relative mx-auto filter dark:invert " v-if="liked" :class="size == 'big' ? 'h-5' : 'w-4'"/>
      <img src="../../public/img/pictos/heart.svg" class="relative mx-auto  filter dark:invert" v-else :class="size == 'big' ? 'h-5' : 'w-4'"/>
    </transition>
  </button>
</template>

<script>
import { ref } from "vue";
import store from "@/store";

import UserService from '@/services/user.service'

export default {
  props: ['article','size'],
  setup() {
    const liked = ref(false);
    const initial_liked = ref(false);
    return {
      liked,
      initial_liked,
    };
  },
  mounted() {
    this.liked = this.article?.user_like
    this.initial_liked = this.article?.user_like
  },
  methods: {

    animPastilles() {
      let pastilles = document.querySelectorAll('.pop-top');
      pastilles.forEach(pastille => {
        pastille.classList.remove('anim-pastille')
        void pastille.offsetWidth;
        pastille.classList.add('anim-pastille')
      });
    },
    toggleLike() {
      this.initial_liked = false
      if(this.liked) {
        store.commit("favoris/removeFavoris");
        this.liked = false;
        UserService.dislike(this.article.id)
          .then(res => {
            // a non-200 response code
            if (res.status !== 200) {
              const error = new Error(res.statusText);
              error.json = res;
              throw error.json.statusText;
            }
            if(res.data.message.indexOf('unlike') == -1) {
              this.liked = true;
              store.commit("favoris/addFavoris");
            }
            // articles.value = res.data.data;
          })
      } else {
        this.liked = true;
        store.commit("favoris/addFavoris");
        this.animPastilles();
        UserService.like(this.article.id).then((res) => {
          if(res.data.message.indexOf('liked') == -1) {
            this.liked = false;
            store.commit("favoris/removeFavoris");
          }
        })
      }
    }
  }
}
</script>

<style scoped>

.heart {
  @apply absolute transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
  /* width: 300%;
  height: 300%; */
  @apply bg-no-repeat;
  background-position:left;
  background-image:url("../../public/img/pictos/web_heart_animation-light.png");
  /* background-image:url("../../public/img/pictos/.png");  */
  /* background-size:2900%; */
}

html.dark .heart {
  background-image:url("../../public/img/pictos/web_heart_animation-dark.png");

}
.heart:hover {
  background-position:right;
}

.heart.is_animating:not(.to_the_end) {
  animation: heart-burst .8s steps(28) 1 forwards;
}

.heart.to_the_end {
  background-position:right;
}

@keyframes heart-burst {
  from {background-position:left;}
  to { background-position:right;}
}
</style>
