import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue'
import Archives from '@/views/Archives.vue'
import Brands from '@/views/Brands.vue'
import AllBrands from '@/views/AllBrands.vue'
import SingleBrand from '@/views/SingleBrand.vue'
import Favoris from '@/views/Favoris.vue'

import Login from '@/views/Login.vue'
import PageNotFound from '@/views/PageNotFound.vue'

import store from "@/store";

const routes = [
  {
    path: '/login',
    name: "Login",
    // component: () => import('@/views/Login.vue'),
    component: Login,
    meta: { transition: 'slidePopup' }
  },
  {
    path: '/',
    name: "Home",
    component: Home,
  },
  {
    path: '/archives',
    name: "Archives",
    component: Archives,
  },
  {
    path: '/brands',
    component: Brands,
    children: [
      {
        path: "",
        name: "Brands",
        component: AllBrands,
      },
      {
        path: "/brands/:name",
        name: "SingleBrand",
        component: SingleBrand,
      },
    ]
    // meta: { transition: 'slideFromBottom', mode: 'in-out' },
  },
  {
    path: "/favoris",
    name: "Favoris",
    component: Favoris,
  },
  { path: "/:pathMatch(.*)*", component: PageNotFound }
]

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
  scrollBehavior(to) {
    if(to.hash) {
      return false;
    }
    return { top: 0 }
  },
})

router.beforeEach((to, from) => {

  // trying to access a restricted page + not logged in + avoid infinite loop
  // redirect to login page
  const publicPages = ['/login', '/forgot-password'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  if (authRequired && !loggedIn && to.name != "Login") {
    return {
      name: 'Login',
      query: to.path != '/' ? { redirect: to.path } : ""
    }
  }

  if(to.name == "Login" && loggedIn) {
    return {
      name: 'Home',
    }
  }
  // close single post panel on goback when it's open

  if(store.state.post.post && from.hash == '#post') {
    store.commit('post/hide')
    return false;
  }
});

export default router
