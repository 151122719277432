export const brand = {
  namespaced: true,
  state: {
    brand: null,
  },
  actions: {
    update({ commit }) {
      commit("update");
    },
  },
  mutations: {
    update(state, brand) {
      state.brand = brand;
    },
  }
}
