<template>
  <div class="bg-cover bg-center bg-no-repeat flex items-center z-50 relative min-h-full" style="background-image:url('/img/R-DAM_1336987.jpg')">
    <div class="container px-2 py-8 mx-auto">
      <div class="bg-white rounded-md p-10 mx-auto max-w-sm popup">
        <img src="img/rg-logo-vertical.svg" alt="Logo Renault Group" width="133" height="119" class="mx-auto mb-10"/>
        <p class="text-2xl mb-8 text-center">Connexion</p>
        <form @submit.prevent="handleLogin">
          <input type="email" v-model="user.email" placeholder="Adresse email" class="mb-3 w-full px-4 py-2 border border-gray-400 rounded" required>
          <!-- <input type="password" v-model="user.password" placeholder="Mot de passe" class="mb-5 w-full px-4 py-2 border border-gray-400 rounded" required> -->
          <button type="submit"
                  class="mb-5 w-full px-4 py-2 rounded text-white bg-black hover:bg-dark uppercase"
                  :class="loading ? 'opacity-50 cursor-wait' : ''"
                  :disabled="loading">
            Continuer
          </button>
        </form>
        <div class="text-center">
          <p class="text-red break-words" v-show="!loading && error">{{ error }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';

export default {
  setup() {
    const user = reactive({
      email: null,
      // password: "zUSMkWzxYGw3RD3#",
    });
    const loading = ref(false);
    const error = ref(null);

    return {
      user,
      loading,
      error,
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$store.dispatch("auth/login", this.user).then(
        () => {
          this.$router.push(this.$route.query.redirect || '/');
        },
        (error) => {
          this.loading = false;
          this.error =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  }
}
</script>

<style>

</style>
