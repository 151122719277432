<template>
  <div class="min-h-full flex items-center">
    <div class="container lg:max-w-none pb-20 pt-10 lg:pb-10 lg:pt-0 flex flex-col items-center justify-center min-h-full">
      <h1 class="text-center text-4xl md:text-5xl lg:text-6xl xl:text-7xl max-w-xxs md:max-w-xs lg:max-w-none mt-6 mb-10 sm:mt-8 sm:mb-14 md:mt-12 md:mb-20 lg:mt-0 dark:text-white transition-colors duration-500 font-semibold">
        L’actualité des marques
      </h1>
      <div class="brands flex justify-center flex-wrap gap-4 lg:gap-6 xl:gap-6 w-full">
        <template v-if="brands && brands.length">
          <Brand v-for="brand of brands" :key="brand" :brand="brand"/>
        </template>
        <template v-if="!brands && !error">
          <LoadingBrand v-for="n of 9" :key="n"/>
        </template>
      </div>
      <p v-if="error" class="mt-8 text-center dark:text-white transition-colors duration-500">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import Brand from '@/components/Brand.vue';
import LoadingBrand from '@/components/loading/Brand.vue';

import UserService from '@/services/user.service';

import { onMounted, ref } from "vue";

export default {
  name: 'Brands',
  components :{
    Brand,
    LoadingBrand
  },
  setup() {
    const brands = ref(null);
    const error = ref(null);

    function fetchBrands() {
      UserService.getBrands()
        .then(res => {
          // a non-200 response code
          if (res.status !== 200) {
            const error = new Error(res.statusText);
            error.json = res;
            throw error.json.statusText;
          }
          brands.value = res.data.data;

        })
        .catch(err => {
          error.value = err;
          if (err.json) {
            return err.json.then(json => {
              error.value.message = json.message;
            });
          }
        })
    }

    onMounted(() => {
      fetchBrands();
    });

    return {
      brands,
      error,
    };
  }
}
</script>
