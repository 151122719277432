<template>
  <div class="fixed w-full top-0 left-0 z-30 h-10" :class="$route.name == 'Home' && head_top_news || $route.name == 'Archives' ? 'home-header transform -translate-y-full' : ''">
    <div class="lg:hidden absolute w-full top-0 left-0 z-30 h-10 mobile-header transition-colors duration-500 bg-light dark:bg-dark">
      <div class="container mx-auto px-4 flex justify-between items-center h-full">
        <router-link :to="{ name: 'Home' }">
          <img src="/img/Renault_Group.svg" alt="Renault Group Logo" class="filter dark:invert transition-all duration-500">
        </router-link>
        <div class="flex items-center gap-2">
          <DarkMode/>
          <Bell/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bell from '@/components/Bell.vue'
import DarkMode from '@/components/DarkMode.vue'


export default {
  name: 'MobileHeader',
  props: ['head_top_news'],
  components: {
    DarkMode,
    Bell,
  },
  setup() {
  },
}
</script>
