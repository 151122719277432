<template>
	<div
		@click.prevent="showPost"
		class="w-full h-full bg-white dark:bg-black dark:text-white
					transition-colors duration-500
					rounded-md overflow-hidden relative
					cursor-pointer remove-tap-highlight
					flex flex-col">
			<div
					class="bg-green bg-cover bg-center bg-no-repeat flex-shrink-0 relative"
					:class="ratio ? ratio : 'ratio-16/9'"
					:style="article.image == null ? 'background-image:url(/img/blurry3.jpg);filter: grayscale(1);'  : `background-image:url(${article.image?.url});  `"
			>
			<div v-if="article.brand?.front_label || article.brand?.name" class="absolute top-4 left-4 right-4">
				<div class="bg-white text-dark text-sm rounded-full px-4 py-2 inline-block">
					{{ article.brand?.front_label ? article.brand.front_label : article.brand?.name }}
				</div>
			</div>
		</div>

		<div class="py-6 flex flex-col justify-between z-10 px-4 h-full">
			<div class="flex items-center justify-between">
				<p class="mb-0 text-sm text-green dark:text-greenLight transition-colors duration-500">
					{{ article.source }}
				</p>
				<BtnLike :article="article" size="small" />
			</div>
			<h2 class="my-4 leading-super-snug text-lg font-semibold">
				{{ article.title }}
			</h2>
			<div class="flex justify-between items-center text-greyDarken">
				<p v-if="dateInterval" class="flex-none text-xs text-greyDarken">
					{{ dateInterval }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import BtnLike from "@/components/BtnLike.vue";
import store from "@/store";
import moment from 'moment';

export default {
	props: ["article", "ratio"],
	components: {
		BtnLike,
	},
	computed: {
		dateInterval() {
			moment.locale('fr');
			return moment(this.article.publication_date,"YYYY-MM-DD hh:mm:ss").fromNow()
		},
	},
	methods: {
		showPost() {
			// let route = this.$route.name
			this.$router.push({name:this.$route.name, hash:'#post'})
			store.commit("post/show", this.article);
		},
	},
};
</script>
