import UserService from '../services/user.service';

export const favoris = {
  namespaced: true,
  state: {
    count: localStorage.getItem('favorisCount'),
  },
  actions: {
    getFavorisCount({ commit }) {
      UserService.getLiked()
        .then(res => {
          // a non-200 response code
          if (res.status !== 200) {
            const error = new Error(res.statusText);
            error.json = res;
            throw error.json.statusText;
          }
          commit('updateFavorisCount', res.data.data.length);
        })
        .catch(err => {
          return Promise.reject(err);
        })
    },
  },
  mutations: {
    updateFavorisCount(state, count) {
      localStorage.setItem('favorisCount', count);
      state.count = count;
    },
    addFavoris(state) {
      state.count++
      localStorage.setItem('favorisCount', state.count);
    },
    removeFavoris(state) {
      state.count--
      localStorage.setItem('favorisCount', state.count);
    }
  }
}
