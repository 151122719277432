import { createStore } from "vuex";
import { auth } from "./auth.module";
import { post } from "./post.module";
import { brand } from "./brand.module";
import { notification } from "./notification.module";
import { favoris } from "./favoris.module";

const store = createStore({
  modules: {
    auth,
    post,
    brand,
    notification,
    favoris
  },
});
export default store;
