<template>
    <div class="cp-overlay"
      :class="{ ' h-full fixed top-0 bottom-0 left-0 right-0 z-60': show,  'no-overlay': !active }">
      <transition name="vue-fade">
        <div v-if="show && active" class="overlay-layer absolute top-0 left-0 right-0 bottom-0 z-70"  style="background-color: rgba(43, 46, 56, 0.9);"></div>
      </transition>
      <transition :name="slide">
        <div v-if="show" class="overlay-inner  left-0 lg:left-auto top-0 h-full bottom-0 absolute right-0 z-80 bg-black   py-6  px-6" >
            <div  @click="closeModal" v-if="active" class="absolute z-20 lg:top-6 top-6  right-6 h-8 w-8 bg-white ml-auto transform transition-all cursor-pointer rounded-full duration-500 backdrop-filter backdrop-blur-sm remove-tap-highlight flex flex-col items-center justify-center">
                        <img src="/img/pictos/cross.svg" class="filter duration-500" width="12" height="12" />
            </div>
         
          <div class="overlay-content h-full ">
            <slot name="content"></slot>
          </div>
        </div>
      </transition>
    </div>
  </template>

  <script >
    export default {
        data() {
            return {
                slide: 'slideFromRight'
            }
        },
        props: {
            show: {
                type: Boolean,
                required: false,
                },
                active: {
                type: Boolean,
                required: false,
                default: true,
                },
                sidebarInDesktop: {
                type: Boolean,
                required: false,
                default: false,
                },
        },

        methods: {
            closeModal() {
                this.$emit('close-modal');
            },
            sizeWindow(){
                    window.innerWidth > 1024 ? this.slide = 'slideFromRight' : this.slide = 'fadeFromBottom';
            }
        },

        mounted () {
            this.sizeWindow();
        },
        
    }

  
  </script>
  
