<template>
	<div class="lg:max-w-screen-lg mx-auto lg:pt-48 dark:text-white pt-10 pb-10 lg:pb-48 overflow-auto px-4 article-detail">
		<p class="text-sm pb-3  text-white" >{{ article.source }} <span class="w-0.5 h-0.5 mx-1 md:mx-2 mb-0.5 bg-lightGreen rounded-full inline-block transition-color duration-500"></span> {{ formatDate }}</p>
		<p class="text-tiny md:text-6xl font-bold text-white">{{ article.title }}</p>
		<div class="flex gap-3 pt-8 flex-col lg:flex-row">


            <button class="text-white text-sm rounded-md px-16 py-4 bg-mineral hover:bg-white hover:bg-opacity-30 whitespace-nowrap flex items-center justify-center gap-2" @click="refactoArticle(article.full_text)" v-if="article.ai_rephrase == null">
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="16px" height="16px"><path d="M 20 2 C 19.702375 2 19.405781 2.1488125 19.238281 2.4453125 L 17.869141 4.859375 L 15.197266 4.1171875 C 14.540266 3.9351875 13.935187 4.5402656 14.117188 5.1972656 L 14.859375 7.8691406 L 12.445312 9.2363281 C 11.852313 9.5723281 11.852313 10.426719 12.445312 10.761719 L 14.859375 12.128906 L 14.865234 12.134766 L 18.378906 8.6191406 C 19.206906 7.7911406 20.550906 7.7911406 21.378906 8.6191406 C 22.206906 9.4471406 22.206906 10.791141 21.378906 11.619141 L 17.865234 15.134766 L 17.871094 15.140625 L 19.236328 17.554688 C 19.572328 18.147687 20.428672 18.147687 20.763672 17.554688 L 22.128906 15.140625 L 24.802734 15.880859 C 25.459734 16.062859 26.062859 15.459734 25.880859 14.802734 L 25.140625 12.128906 L 27.554688 10.763672 C 28.148687 10.427672 28.147688 9.5723281 27.554688 9.2363281 L 25.140625 7.8691406 L 25.882812 5.1972656 C 26.064812 4.5402656 25.459734 3.9351875 24.802734 4.1171875 L 22.130859 4.859375 L 20.763672 2.4453125 C 20.595672 2.1488125 20.297625 2 20 2 z M 14.863281 12.136719 L 3.6210938 23.378906 C 2.7930938 24.206906 2.7930938 25.550906 3.6210938 26.378906 C 4.4490937 27.206906 5.7930938 27.206906 6.6210938 26.378906 L 17.863281 15.136719 L 14.863281 12.136719 z M 18.984375 19.986328 A 1.0001 1.0001 0 0 0 18 21 L 18 22 L 17 22 A 1.0001 1.0001 0 1 0 17 24 L 18 24 L 18 25 A 1.0001 1.0001 0 1 0 20 25 L 20 24 L 21 24 A 1.0001 1.0001 0 1 0 21 22 L 20 22 L 20 21 A 1.0001 1.0001 0 0 0 18.984375 19.986328 z M 25.984375 22.986328 A 1.0001 1.0001 0 0 0 25 24 L 25 25 L 24 25 A 1.0001 1.0001 0 1 0 24 27 L 25 27 L 25 28 A 1.0001 1.0001 0 1 0 27 28 L 27 27 L 28 27 A 1.0001 1.0001 0 1 0 28 25 L 27 25 L 27 24 A 1.0001 1.0001 0 0 0 25.984375 22.986328 z" fill="white"/></svg>
				Mise en forme IA
			</button>

            
			<button class="text-white text-sm rounded-md px-16 py-4 bg-mineral hover:bg-white hover:bg-opacity-30 whitespace-nowrap flex items-center justify-center gap-2" @click="showResume(article.ai_summary == null ? true : false)">
				<img src="/img/pictos/ia.svg" width="16" class="filter transition-all duration-500 transform rotate-45" />
				Résumé de l'article
			</button>

			<div class="relative transform bg-cover bg-center bg-no-repeat overflow-hidden rounded-md flex justify-center bg-black">
                
				<button @click="showSource" v-if="article.media_url == null" class="text-white text-sm rounded-md px-16 py-4 hover:bg-white hover:bg-opacity-30 whitespace-nowrap flex items-center justify-center gap-2 w-full">
					<img src="/img/pictos/extern.svg" width="13" class="fill-white transition-all duration-500 transform filter" />
					Afficher la source
				</button>
                <a :href="article.media_url" v-else class="text-white text-sm rounded-md px-16 py-4 hover:bg-white hover:bg-opacity-30 whitespace-nowrap flex items-center justify-center gap-2 w-full">
                    <img src="/img/pictos/extern.svg" width="13" class="fill-white transition-all duration-500 transform filter" />
					Afficher la source
                </a>

			</div>
		</div>
        <div class=" flex flex-rowlg:mb-16 mb-10 gap-1 items-center  py-1 px-1 mt-4">
            <img src="/img/pictos/infos.svg" width="16" class="fill-white transition-all duration-500 transform filter" />
 <small class="font-light italic" >L'application inclut maintenant des fonctionnalités d'IA pour une expérience améliorée ! Vos retours sont bienvenus pour améliorer l'outil.</small></div>


        <!--  TODO ARTICLE REFORMATE -->
        <div class="content-article" v-html="article.ai_rephrase" v-if="article.ai_rephrase"></div>
        <div  class="content-article" v-html="article.full_text" v-if="article.ai_rephrase == null && generate == false"> </div>

        <div v-html="summarizedText " class="content-article" v-if="article.ai_rephrase == null"></div>

		<Teleport to="body">
			<transition name="slideFromRight" mode="out-in">
				<div class="min-h-full fixed z-50 bg-black flex top-0 left-0 w-full " v-show="show">
					<div @click.prevent="close" class="fixed z-20 lg:top-16 top-10 lg:right-16 right-10 h-12 w-12 bg-white ml-auto transform transition-all cursor-pointer rounded-full duration-500 backdrop-filter backdrop-blur-sm remove-tap-highlight flex flex-col items-center justify-center">
						<img src="/img/pictos/cross.svg" class="filter duration-500" width="9" height="9" />
					</div>
					<div class="lg:max-w-screen-lg lg:pt-48 dark:text-white pt-20 pb-10 lg:pb-48 h-screen overflow-auto px-4 mx-auto">
						<p class="text-sm pb-3 text-white">{{ article.source }} <span class="w-0.5 h-0.5 mx-1 md:mx-2 mb-0.5 bg-lightGreen rounded-full inline-block transition-color duration-500"></span> {{ formatDate }}</p>
						<p class="text-tiny font-semibold text-white">{{ article.title }}</p>


                        <!-- <div class="content-resume-article" v-html="article.ai_summary"></div> -->
                        <div class="content-resume-article" v-html="summarizeAppear"></div>

                        <div  class="content-resume-article" v-html="summarizedText2" v-if="article.ai_summary == null"></div>
						
                        
                        
                        <!--  TODO ARTICLE RESUME -->
                        <div class="text-base font-semibold mt-10"> Qu’avez-vous pensé de ce résumé ?</div>
                        <div class=" flex  gap-6 mt-4 items-center flex-row " v-if="goodResume == 0">
                            <button @click="resumeCheck(article.id, 1)" class="text-white text-sm rounded-md lg:px-32 px-auto  w-full  lg:w-max py-2 lg:py-4 bg-dark hover:bg-white hover:bg-opacity-30 whitespace-nowrap flex items-center justify-center gap-2 ">
								<img src="/img/pictos/thumbs.svg" width="20" class="fill-white transition-all duration-500 transform filter" />
								J'aime 
							</button>
                            <button @click="resumeCheck(article.id, 2)" class="text-white text-sm rounded-md lg:px-32 px-auto  w-full lg:w-max py-2 lg:py-4 bg-dark hover:bg-white hover:bg-opacity-30 whitespace-nowrap flex items-center justify-center gap-2 "  >
								<img src="/img/pictos/thumbs-down.svg" width="20" class="fill-white transition-all duration-500 transform filter " />
								Je n'aime pas
							</button>
                        </div>

                        <p  v-if="goodResume !== 0" class="mt-5 font-light text-base">Votre retour a bien été transmis.</p>
                        

						<div class="relative transform bg-cover bg-center bg-no-repeat overflow-hidden rounded-md flex bg-black mt-10 ">
							<a @click="showSource" class="text-white text-sm rounded-md  px-auto lg:px-24 py-4 bg-dark hover:bg-white hover:bg-opacity-30 whitespace-nowrap flex items-center justify-center gap-2 w-full  lg:w-max"  v-if="media_url == null">
								<img src="/img/pictos/file.svg" width="13" class="fill-white transition-all duration-500 transform filter" />
								Lire l'intégralité de l'article
							</a>
                            <a :href="article.media_url" v-else class="text-white text-sm rounded-md px-16 py-4 hover:bg-white hover:bg-opacity-30 whitespace-nowrap flex items-center justify-center gap-2 w-full">
                                <img src="/img/pictos/extern.svg" width="13" class="fill-white transition-all duration-500 transform filter" />
                                Afficher la source
                            </a>
						</div>
					</div>
				</div>
			</transition>
		</Teleport>

        <Teleport to="body">
            <div  class="w-full h-screen pb-20 overflow-scroll">
      <pdf :source="article?.media_file?.url"
           @rendering-failed="handleRenderingFailed"
           @loading-failed="handleLoadingFailed"/>
    </div>
        </Teleport>
	</div>
</template>

<script>
import moment from "moment";
import userService from "../services/user.service";
import OpenAI from "openai";
export default {
	props: ["article"],
	data() {
		return {
			show: false,
			openai: null,
			summarizeLoading: false,
			generate: false,
            openaiNew: null,
            summarizedText: "",
            summarizedText2: "",
            goodResume: this.article.user_review,
            summarizeAppear: '',
		};
	},
	methods: {
		async showResume(value) {
			this.show = true;
            if(value == true && this.summarizedText2 == '') {
                let text3 = `Vous êtes un expert en rédaction et SEO.
                Votre objectif : Générer un résumé clair et précis à partir d'un texte extrait d'un article web. 
                Le résumé doit être professionnel, contenir les informations clés et maintenir la cohérence du contenu original.

                Texte à résumer : ${this.summarizedText != '' ? this.summarizedText : this.article.full_text}

                Points à inclure dans le Résumé :
                Principaux sujets abordés.
                Résultats clés et/ou conclusions.
                Recommandations, le cas échéant.

                Instructions :
                1. Veillez à ce que le texte soit bien formaté et lisible dans le cadre d'une page web en sépérant les titres possibles, ajoutant des sauts de lignes, paragraphes, etc. Les titres doivent être hiérarchisés avec des balises Hn, les paragraphes avec la balise <p class='article-inner-p'>, les listes dans des balises <ul>.
                2. Retirez les mentions liées à la publication (par exemple 'Publié le/par' et 'Mis à jour le/par', l'auteur, 'Référence', etc).
                3. Faites en sorte que le résumé contienne environ 200 mots.
                4. Si le texte d'origine fait déjà moins de 200 mots, ne pas appliquer l'instruction 3.
                5. Retournez le contenu au format HTML, wrappé entre des balises <div>, retirez les retours à la ligne (\n), les sauts de ligne (<br>), et tout autre caractère de fin de ligne, nous les ajouterons en CSS.
                6. Important : Le contenu doit être retourné en ${this.article.language}, les titres comme les paragraphes.

                <div>
                    [Résumé du contenu de l'article sous forme de texte explicatif, pas de listes, le plus court et synthétique possible.]
                    <p class='article-inner-p'>[Contenu]</p>
                    [Conclusions, chiffres clés, informations importantes]
                    <h2>Key results and conclusions</h2>
                    <ul>
                        <li>[Contenus résultats clés]</li>
                    </ul>
                    <p class='article-inner-p'>[Contenu de conclusion sous forme de paragraphe]</p>
                    [Si il y a des recommandations]
                    <h2>Recommendations</h2>
                    <p class='article-inner-p'>[Contenu]</p>
                    <p class='article-inner-p'>[Contenu si besoin de plusieurs paragraphes]</p>
                    [/Si il y a des recommandations]
                </div>
                `
                const stream = await this.openaiNew.chat.completions.create({
                    model: "gpt-3.5-turbo-16k",
                    stream: true,
                    messages: [{
                    "role": "user", 
                    "content": text3
                    }],
                });
                for await (const chunk of stream) {
                    this.generate2 = true
                    this.summarizedText2  +=  chunk.choices[0]?.delta?.content || ""
                }
            }
            else {
                this.animateSummarizedContent();
            }
		},

        animateSummarizedContent() {
            if (this.summarizeAppear.length > 0 || !this.article.ai_summary) return;
            this.article.ai_summary.split('').forEach((char, timerCount) => {
                setTimeout(() => {
                    this.summarizeAppear += char;

                }, timerCount * 8)
            })
        },

        resumeCheck(id, value) {
            userService.postFeedBack(id, value )
            this.goodResume = value
        },

		close() {
			this.show = false;
		},

        async refactoArticle(text)  {
            if(this.summarizedText !== '') return
            this.summarizeLoading = true;
            let text2 = `Vous êtes un expert en rédaction et SEO,  Votre objectif : reformer un texte avec les balises HTML afin qu'il soit optimisé pour le SEO. Pas besoin de recréer la page HTML en entier, et il ne faut pas rajouter du contenu ni de titre. 
                Voici le texte à modifier, il s'agit d'un article de presse : '${text}'
                1. Retirez toutes les balises <p>, et remplacez les retours à la ligne (\n), les sauts de ligne (<br>), et tout autre caractère de fin de ligne par un caractère espace.
                2. Supprimez tous les mots isolés ou incohérents qui ne semblent pas avoir de signification dans le contexte.
                3. Supprimez tous les textes de légende ou de description associés aux illustrations ou autres éléments non pertinents pour le contenu principal, ainsi que des dates ou heures qui ne font pas partie de l'article.
                4. Le contenu texte est issu d'un scrapping global, il se peut qu'il y ait des textes de menu qu'on ne veut pas voir apparaître. Supprimez-les aussi. Exemple : 'Save', 'Font', 'Comments', 'Follow us on twitter'.
                5. Retirez toutes les mentions liées à l'article, telles que les dates de publication, les noms d'auteur, les références, etc.
                6. Ne reformulez pas le contenu, gardez les textes tels qu'ils sont et conservez-le en intégralité, exception faite des demandes précédentes.

                    7. Conservez impérativement l'intégralité du texte fourni
                    8. Entourez le texte dans des balises div
                    9. Dans un souci d'amélioration de SEO, découpez le contenu en paragraphes cohérents
                    10. Si le texte contient des listes, englobez-les dans les balises <ul><li>
                    11. Si des éléments ressemblent à des sous-titres, englobez-les dans des balises <h4 class='sub-title'>
                    12. Retournez l'intégralité de la <div></div> englobante

                `
            const stream = await this.openaiNew.chat.completions.create({
                model: "gpt-3.5-turbo-16k",
                stream: true,
                messages: [{
                "role": "user", 
                "content": text2
                }],
            });
            for await (const chunk of stream) {
                this.generate = true
                this.summarizedText  +=  chunk.choices[0]?.delta?.content || ""
            }
            this.summarizeLoading = false;
        },

        showSource() {
            if(this.article.media_url != null) {
                
                this.show = false;
                window.open(this.article.media_url).focus()
            }
            else {
                this.$emit('show-source', this.article)
            }
        }
	},
	computed: {
		formatDate() {
			moment.locale("fr");
			return moment(this.article.publication_date).format("LL");
		},
	},

    mounted () {
        this.openaiNew = new OpenAI({
            apiKey: process.env.VUE_APP_OPENAI_API_KEY,
            dangerouslyAllowBrowser: true,
        });
    },
};
</script>
