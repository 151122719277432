<template>
	<div class="pb-20 lg:pb-10 flex flex-col items-center justify-center min-h-full scrollable" v-if="articles !== null " >

        <div class="flex items-stretch w-full bg-cover bg-center bg-no-repeat overflow-hidden"  >
            <HomeHeader  />
		</div>

		<!-- POSTS NORMAUX -->
		<div class="w-full pb-10  overflow-hidden" >
			<!-- ERROR MESSAGE -->
			<p v-if="errorArticles" class="container text-center mt-8 dark:text-white transition-colors duration-500">
				{{ errorArticles }}
			</p>

            <div class="container flex justify-center  pt-2 lg:pt-28 dark:bg-dark bg-light container-filter" :style="isMobile ? 'height: 56px;' : ''">
				<button class="text-white text-sm rounded-md px-24 py-4 bg-mineral whitespace-nowrap flex items-center justify-center gap-2 lg:w-max w-full font-semibold  filter-sticky" @click="openFilter"  :class="{'fixed top-0 z-40 fixed-style': fixed && isMobile}" >
					<img src="/img/pictos/filter.svg" width="18" class="filter transition-all duration-500 transform rotate-180" />
					Filtrer l'actualité
				</button>
			</div>

			<div v-for="(article, key) in articles" :key="key" >
                <template v-if="hasArticles(article)">

                    <template v-if="filtersList[key].length > 0 || (filtersList['Marques'].length == 0 && filtersList['Secteurs'].length == 0 && filtersList['Corporate'].length == 0 )  ">
                        <div class="container pt-10">
                            <span class="dark:text-white lg:text-32 text-24 font-semibold">{{ key == 'Secteurs' ? 'Thématiques': (key == 'Marques' ? 'Marques et entités' : key)  }} </span>
                        </div>
                        <div v-for="brand in article.brands" :key="brand.id">
                            <div class="container lg:pb-10 pb-6 pt-6 lg:pt-10" v-if="(filtersList[key].includes(brand.slug) || filtersList[key].length == 0) && brand.articles && brand.articles.length > 0">
                                <div class="dark:text-white text-sm pb-6 font-light flex justify-between">
                                    <div>
                                        {{ brand.front_label == null ? brand.name : brand.front_label }} <span v-if="isMobile">({{ brand.articles.length }})</span>
                                    </div>
                                    <span v-if="!isMobile">{{ brand.articles.length }} {{ brand.articles.length > 1 ? "articles" : "article" }}</span>
                                </div>
                                <div class="h-px w-full border-b border-grey2"></div>
                            </div>
                            <transition name="vue-accordion">
                                <template class="container grid grid-flow-row-dense grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 md:gap-6 lg:gap-8 xl:gap-10">
                                    <template v-for="art in brand.articles" :key="art.id">
                                        <CardPost :article="art" :layout="art.layout" v-if="filtersList[key].includes(brand.slug) || filtersList[key].length == 0" />
                                    </template>
                                </template>
                            </transition>
                        </div>
                    </template>
                </template>
			</div>

            <div class="container grid grid-flow-row-dense grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 md:gap-6 lg:gap-8 xl:gap-10 pt-10">
                <template v-if="loading">
                    <LoadingCardPost v-for="n of 6" :key="n"/>
                </template>
            </div>
		</div>

		<cp-overlay :show="showFiltersOverlay" :sidebarInDesktop="true" @close-modal="closeFiltersModal">
			<template v-slot:content>
				<cp-filters-and-sorters :tags="articles" @apply-filters="filterChose" :filters-list="filtersList" @close-filters-modal="closeFiltersModal" />
			</template>
		</cp-overlay>
	</div>
    <div v-if="articles == null" class="" >
        <HomeHeader  class="bg-dark" />
        <div class=" flex  justify-center items-center min-h-full flex-col gap-10 text-center h-screen container">

            <div  class="font-light dark:text-white text-24">
                la revue de presse a pris son week-end... vous retrouverez les archives dès demain !
            </div>
        </div>
    </div>
</template>

<script>
import CardPost from "@/components/CardPost.vue";
import CpOverlay from "../components/CpOverlay.vue";
import CpFiltersAndSorters from "../components/CpFiltersAndSorters.vue";
import LoadingCardPost from '@/components/loading/CardPost.vue'
import { onMounted, ref, onUnmounted } from "vue";
import UserService from "../services/user.service";
import HomeHeader from "@/components/HomeHeader.vue";
export default {
	components: {
		CardPost,
		CpOverlay,
		CpFiltersAndSorters,
        LoadingCardPost,
        HomeHeader
	},
	name: "Archives",
	setup() {
		const articles = ref();
		const loading = ref(true);
		const errorArticles = ref(null);
		const showFiltersOverlay = ref(false);
		const isMobile = ref(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
		const filtersList = ref({
			Marques: [],
			Corporate: [],
			Secteurs: [],
		});
        const isVisible = ref(true)
        const elementPosition = ref(0)
        const lastScrollTop = ref(0)
        const fixed = ref(false)

        const hasNoArticles = (data) => {
            for (const key in data) {
                const section = data[key];
                if (section.brands && Array.isArray(section.brands)) {
                    for (const brand of section.brands) {
                        if (brand.articles && brand.articles.length > 0) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }

        const fetchArticles = () => {
			loading.value = true;
			UserService.getPosts(1)
				.then((res) => {
					if (res.status !== 200) {
						const error = new Error(res.statusText);
						error.json = res;
						throw error.json.statusText;
					}
                    if(hasNoArticles(res.data) == true  ) {
					articles.value = res.data;
                    }
                    else {
                        articles.value = null
                    }

                    
				})
				.catch((err) => {
					errorArticles.value = err;
					if (err.json) {
						return err.json.then((json) => {
							errorArticles.value.message = json.message;
						});
					}
				})
				.then(() => {
					loading.value = false;
				});
		};

		const openFilter = () => {
			showFiltersOverlay.value = true;
		};

		const closeFiltersModal = () => {
			showFiltersOverlay.value = false;
		};


		const filterChose = (value) => {
			filtersList.value = value;
		};

        const hasArticles = (value) => {
            return value.brands.some(brand => brand.articles && brand.articles.length > 0);
        }

        const filterSticky = () => {
            elementPosition.value = document.querySelector('.filter-sticky').getBoundingClientRect().top;


            var st = window.scrollY || document.documentElement.scrollTop; 
            if (st > lastScrollTop.value ) {
               if(elementPosition.value <= 0 && fixed.value == false ) {
                    fixed.value = true
               }
            } else if (st < lastScrollTop.value) {
               
                if(st <= document.querySelector('.container-filter').offsetTop && fixed.value == true ) {
                    fixed.value = false
               }
            } 
            lastScrollTop.value = st <= 0 ? 0 : st;
        }

		onMounted(() => {
			fetchArticles();
            window.addEventListener('scroll', filterSticky);
		});
        onUnmounted(() => {
            window.removeEventListener('scroll', filterSticky);
        })
		return {
			articles,
			loading,
			errorArticles,
			fetchArticles,
			openFilter,
			showFiltersOverlay,
			closeFiltersModal,
			CpFiltersAndSorters,
			filterChose,
			isMobile,
			filtersList,
            hasArticles,
            isVisible,
            fixed
		};
	},
	updated() {},
	methods: {},
};
</script>


