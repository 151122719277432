export const post = {
  namespaced: true,
  state: {
    post: null,
  },
  actions: {
    show({ commit }) {
      commit("show");
    },
    hide({ commit }) {
      commit("hide");
    },
  },
  mutations: {
    show(state, post) {
      state.post = post;
      document.querySelector('body').style.overflow = 'hidden'
    },
    hide(state) {
      state.post = null;
      document.querySelector('body').style.overflow = 'visible'
    },
  }
}
