<template>
  <button @click.stop.prevent="toggleDarkMode" class="router-link router-link-btn remove-tap-highlight w-8 h-8 relative">
    <img src="/img/pictos/moon.svg" width="16" height="16"
         class="filter opacity-100 dark:opacity-0 transition-opacity duration-500 absolute top-2 left-2"
         :class="mode == 'light' ? 'invert-0':'invert'">
    <img src="/img/pictos/sun.svg" width="16" height="16"
         class="opacity-0 dark:opacity-100 transition-opacity duration-500 absolute top-2 left-2">
  </button>
</template>

<script>
import { onMounted } from 'vue'

export default {
  props: ['mode'],
  setup() {
    const updateDarkMode = () => {
      if (localStorage.theme === 'dark' || !('theme' in localStorage)) {
        document.documentElement.classList.add('dark')
        document.querySelector('meta[name="theme-color"]').content = '#2B2B2B';
      } else {
        document.documentElement.classList.remove('dark')
        document.querySelector('meta[name="theme-color"]').content = '#f3f3f3';
      }
      // window.requestAnimationFrame(updateDarkMode)
    }
    onMounted(() => {
      updateDarkMode();
    })
    return {
      updateDarkMode,
    }
  },
  methods: {
    toggleDarkMode() {
      localStorage.theme = localStorage.theme === 'dark' ? '' : 'dark';
      this.updateDarkMode()
    }
  },
}
</script>
