<template>
  <div class="pb-20 pt-16 lg:pt-36">
    <div class="w-full transition-colors duration-500 bg-light dark:bg-dark dark:text-white flex fixed lg:relative top-10 lg:top-0 left-0 z-20 header-brand">
      <div class="container flex mx-auto items-center gap-2 relative h-16 lg:mb-8">
        <a @click="goBack" class="router-link router-link-btn remove-tap-highlight flex-shrink-0 cursor-pointer">
          <img src="/img/pictos/arrow.svg" class="filter dark:invert transition-all duration-500 transform -rotate-90">
        </a>
        <p class="text-lg sm:text-xl lg:text-base leading-tight">
          <span class="lg:hidden capitalize">{{ brand?.name }}</span>
          <span class="hidden lg:block">Retour</span>
        </p>

        <h1 class="hidden lg:block mx-auto text-4xl font-semibold pr-28">
          L'actualité <span class="capitalize">{{ brand?.name }}</span>
        </h1>

        <img :src="brand?.logo?.url"
              class="absolute right-0 scale-50 transform
                    filter opacity-100 dark:opacity-0 transition-all duration-500 lg:hidden"/>
        <img :src="brand?.logo_dark_mode ? brand.logo_dark_mode?.url : brand?.logo?.url"
              class="absolute right-0 scale-50 transform
                    filter opacity-0 dark:opacity-100 transition-all duration-500 lg:hidden"/>
      </div>
    </div>

    <div class="container pt-14 lg:pb-4 lg:pt-8 flex flex-col items-center justify-center">
      <div class="grid gap-4 mg:gap-6 lg:gap-6 xl:gap-10 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full">
        <CardPost v-for="article of articles" :key="article" :article="article"/>
        <template v-if="!articles">
          <LoadingCardPost v-for="n of 6" :key="n"/>
        </template>
      </div>
      <p v-if="error" class="my-4">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from 'vuex';

import CardPost from '@/components/CardPost.vue';
import LoadingCardPost from '@/components/loading/CardPost.vue';

import { onMounted, onUnmounted, ref } from "vue";
import { useRoute } from 'vue-router';

import UserService from '@/services/user.service';

export default {
  name: 'SingleBrand',
  components: {
    CardPost,
    LoadingCardPost
  },
  computed: {
    ...mapState('post', ['brand']),
  },
  props: ['loadedBrand'],
  setup() {
    const route = useRoute();
    const articles = ref(null);
    const brand = ref(store.state.brand.brand);
    const loading = ref(true);
    const error = ref(null);
    const nextPageUrl = ref(null);

    const fetchArticles = () => {
      loading.value = true;
      UserService.getPostsByBrand(route.params.name,nextPageUrl.value)
        .then(res => {
          // a non-200 response code
          if (res.status !== 200) {
            const error = new Error(res.statusText);
            error.json = res;
            throw error.json.statusText;
          }
          if(articles.value) {
            articles.value = articles.value.concat(res.data.data);
          } else {
            articles.value = res.data.data
          }
          nextPageUrl.value = res.data.links.next;
        })
        .catch(err => {
          error.value = err;
          if (err.json) {
            return err.json.then(json => {
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }

    const fetchSingleBrandInfos = () => {
      UserService.getSingleBrand(route.params.name)
        .then(res => {
          // a non-200 response code
          if (res.status !== 200) {
            const error = new Error(res.statusText);
            error.json = res;
            throw error.json.statusText;
          }
          brand.value = res.data.data;
        })
        .catch(err => {
          error.value = err;
          if (err.json) {
            return err.json.then(json => {
              error.value.message = json.message;
            });
          }
        })
    }

    const handleScroll = () => {
      // load more articles
      if(!document.querySelector('.scrollable')) return;
      let scrollPos = window.innerHeight + window.scrollY
      let scrollTrigger = document.querySelector('.scrollable').scrollHeight - window.innerHeight
      if(scrollPos > scrollTrigger && !loading.value && nextPageUrl.value) {
        fetchArticles();
      }
    }

    onMounted(() => {
      fetchArticles();
      if(!store.state.brand.brand) {
        fetchSingleBrandInfos();
      }
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {

      window.removeEventListener('scroll', handleScroll);
    })

    return {
      articles,
      loading,
      error,
      brand,
      nextPageUrl
    };
  },
  methods: {
    goBack() {
      if(this.$router.options.history.state.back) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'Brands'})
      }
    }
  },
  updated() {
  },
}
</script>

<style scoped>
.white-brand p {
  @apply text-white;
}
.white-brand img {
  filter: brightness(0) invert(1);
}

.dark-brand p {
  @apply text-black;
}
.dark-brand img {
  filter: brightness(0) invert(0);
}
</style>
