<template>
  <a href="#" @click.prevent="goToBrand(brand)"
               class="flex flex-col relative hover-square  overflow-hidden
                    bg-white bg-opacity-40 border border-white rounded-lg
                      transition-colors duration-500 ratio-1/1 w-40 md:w-32
                    dark:bg-black dark:bg-opacity-40 dark:border-black group"
               :class="brand.text_color == 0 ? 'hover-blanc' : ''">
    <div class="absolute w-full h-full opacity-0 transition-opacity duration-500 group-hover:duration-200 group-hover:opacity-100" :style="'background:'+brand.color"></div>
    <div class="absolute w-full h-full
                p-4 flex-grow flex flex-col justify-center items-center square z-10">
      <div class="flex-grow flex items-center justify-center">
        <img :src="brand?.logo?.url"
             class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 scale-50 md:scale-425 max-w-none
             filter opacity-100 dark:opacity-0 transition-all duration-500
             group-hover:opacity-0 group-hover:duration-200"/>
        <img :src="brand?.logo_dark_mode ? brand.logo_dark_mode.url : brand.logo?.url"
             class="filter opacity-0 dark:opacity-100 transition-all duration-500 scale-50 md:scale-425 max-w-none
             absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2
             group-hover:opacity-100 group-hover:duration-200"/>
      </div>
    </div>
  </a>
</template>

<script>
import store from "@/store";

export default {
  props: ['brand'],
  methods: {
    goToBrand(brand) {
      store.commit("brand/update", this.brand);
      this.$router.push({ name: 'SingleBrand', params: { name: brand.slug } })
    }
  }
}
</script>
