<template>
	<div class="col-span-2 md:col-span-1 lg:col-span-1">
		<div
			class="bg-white dark:bg-black dark:text-white
							transition-colors duration-500
							rounded-md overflow-hidden h-full flex flex-row md:flex-col relative cursor-pointer remove-tap-highlight
							w-full">

			<div class="py-6 px-4 md:py-8 md:px-6 flex flex-col z-10 w-full justify-between h-full">
				<div class="flex items-center">
					<h3 class="mb-0">
						<div class="rounded-xl bg-green bg-opacity-50 h-2.5 w-28 animate-pulse"></div>
					</h3>
				</div>
				<h2 class="my-5">
					<div class="rounded-xl bg-green bg-opacity-50 h-4 w-full mb-2 animate-pulse"></div>
					<div class="rounded-xl bg-green bg-opacity-50 h-4 w-40 max-w-full animate-pulse"></div>
				</h2>
				<div class="flex gap-2 items-center text-greyDarken mt-auto">
					<div class="rounded-xl bg-green bg-opacity-50 h-2.5 w-20 animate-pulse"></div>
					<span class="w-0.5 h-0.5 bg-lightGreen rounded-full block transition-color duration-500"></span>
					<div class="rounded-xl bg-green bg-opacity-50 h-2.5 w-12 animate-pulse"></div>
				</div>
			</div>
		</div>
	</div>
</template>
