<template>
	<div class="cp-filters-and-sorters flex flex-col justify-between pb-10 overflow-auto text-white text-base font-light" style="height: calc(100% - 130px)">
		<div class="cp-filters-and-sorters-body">
			<div class="text-32 font-semibold mb-10">{{ filtersAndSorters.title }}</div>


			<div>
                
                <div class="cp-acordion border-b border-white pb-2" v-if="tags.Corporate">
                    <div class="cp-accordion-title mb-4 flex justify-between items-center cursor-pointer" @click="toggle(1)">
                        Corporate
                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 6.5L6 1.5L1 6.5" stroke="white"/>
                        </svg>

                    </div>
                    <transition name="vue-accordion" @before-enter="onBeforeEnter" @enter="onEnter" @before-leave="onBeforeLeave" @leave="onLeave">
                        <div class="cp-accordion-list" v-show="accordionOpen[1] == true">
								<div class="pb-2">
									<div v-for="item in tags?.Corporate?.brands" class="flex py-1  gap-2 relative items-center" :class="{'text-white text-opacity-50': item.articles.length == 0 }" :key="item.id" @click=" item.articles.length > 0 ? choseCorporate(item.slug) : null " >
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="filterSelected.Corporate.includes(item.slug)" type="checkbox" :id="`${item.slug}`" :value="item.slug " class="pointer-events-none z-1" >
                                            <rect width="16" height="16" fill="#978B7F"/>
                                            <path d="M4 8L6.5 10.5L12 5" stroke="white"/>
                                        </svg>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-else type="checkbox" :id="item.slug" :value="item.slug" class="pointer-events-none z-1" >
                                            <rect x="0.5" y="0.5" width="15" height="15" stroke="#4D4D4D"/>
                                        </svg>
                                        <input v-if="item.id" type="checkbox" :id="`${item.slug}`" :value="item.slug"  class="absolute w-4 h-4 opacity-0 cursor-pointer" />
										<label  class="text-sm" >
											<span class="input"></span>
											<span> {{ item.front_label == null ? item.name : item.front_label }} </span>
										</label>
									</div>
								</div>
							</div>
                    </transition>
                </div>

                <div class="cp-acordion border-b border-white mt-4 pb-2" v-if="tags.Marques" >
                    <div class="cp-accordion-title mb-4 flex justify-between items-center cursor-pointer" @click="toggle(2)">
                        Marques et entités
                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 6.5L6 1.5L1 6.5" stroke="white"/>
                        </svg>
                    </div>
                    <transition name="vue-accordion" @before-enter="onBeforeEnter" @enter="onEnter" @before-leave="onBeforeLeave" @leave="onLeave">
                        <div class="cp-accordion-list"  v-show="accordionOpen[2] == true">
								<div class="pb-2">
                                    <div v-for="item in tags?.Marques?.brands" class="flex py-1  gap-2 relative items-center" :class="{'text-white text-opacity-50': item.articles.length == 0 }" :key="item.id"  @click=" item.articles.length > 0 ? choseBrand(item.slug) : null " >
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if= "filterSelected.Marques.includes(item.slug)" type="checkbox" :id="`${item.slug}`" :value="item.slug " class="pointer-events-none z-2" >
                                            <rect width="16" height="16" fill="#978B7F"/>
                                            <path d="M4 8L6.5 10.5L12 5" stroke="white"/>
                                        </svg>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-else type="checkbox" :id="item.slug" :value="item.slug" class="pointer-events-none z-1" >
                                            <rect x="0.5" y="0.5" width="15" height="15" stroke="#4D4D4D"/>
                                        </svg>
                                        <input v-if="item.id" type="checkbox" :id="`${item.slug}`" :value="item.slug" class="absolute w-4 h-4 opacity-0 cursor-pointer" />
										<label  class="text-sm" >
											<span class="input"></span>
											<span> {{ item.front_label == null ? item.name : item.front_label }} </span>
										</label>
									</div>
									
								</div>
							</div>
                    </transition>
                </div>
                <div class="cp-acordion  mt-4 pb-2" v-if="tags.Secteurs">
                    <div class="cp-accordion-title mb-4 flex justify-between items-center cursor-pointer" @click="toggle(3)">
                        Thématiques
                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 6.5L6 1.5L1 6.5" stroke="white"/>
                        </svg>
                    </div>
                    <transition name="vue-accordion" @before-enter="onBeforeEnter" @enter="onEnter" @before-leave="onBeforeLeave" @leave="onLeave">
                        <div class="cp-accordion-list" v-show="accordionOpen[3] == true">
								<div class="pb-2">
                                    <div v-for="item in tags?.Secteurs?.brands" class="flex py-1  gap-2 relative items-center" :key="item.id" :class="{'text-white text-opacity-50': item.articles.length == 0  }"  @click="item.articles.length > 0 ? choseSectors(item.slug) : null " >
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="filterSelected.Secteurs.includes(item.slug)" type="checkbox" :id="`${item.slug}`" :value="item.slug " class="pointer-events-none z-1" >
                                            <rect width="16" height="16" fill="#978B7F"/>
                                            <path d="M4 8L6.5 10.5L12 5" stroke="white"/>
                                        </svg>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-else type="checkbox" :id="item.slug" :value="item.slug" class="pointer-events-none z-1" >
                                            <rect x="0.5" y="0.5" width="15" height="15" stroke="#4D4D4D"/>
                                        </svg>
                                        <input v-if="item.id" type="checkbox" :id="`${item.slug}`" :value="item.slug" class="absolute w-4 h-4 opacity-0 cursor-pointer" />
										<label  class="text-sm" >
											<span class="input"></span>
											<span v-html="item.name"></span>
										</label>
									</div>
								</div>
							</div>
                    </transition>
                </div>
			</div>
		</div>

		<div class="cp-filters-and-sorters-footer flex flex-col gap-2  bottom-6 fixed items-center" >
			<button class="text-black text-sm  rounded-md py-4 bg-white hover:bg-white hover:bg-opacity-30 whitespace-nowrap flex items-center justify-center gap-2 w-full" @click="applyFilters">{{ filtersAndSorters.actions.submit }}</button>
            <button class="text-black text-sm  rounded-md  py-4 bg-white hover:bg-white hover:bg-opacity-30 whitespace-nowrap flex items-center justify-center gap-2 w-full" @click="resetFilters">Annuler</button>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		// filtersAndSorters: {
		// 	type: Object,
		// },
		selectedFilters: {
			type: Object,
			required: false,
		},
		selectedSort: {
			type: String,
			required: false,
			default: "DESC",
		},
		showSort: {
			type: Boolean,
			required: false,
			default: true,
		},
        tags: {
			type: Object,
			required: false,
		},
        filtersList: {
            type: Object,
            required: false
        }
	},

	data() {
		return {
			chosenSort: this.selectedSort || "",
			sort: { isOpen: false },
            filterSelected: this.filtersList,
            chosenFiltersCorporate: [],
            chosenFiltersBrands: [],
            chosenFiltersSectors: [],
            accordionOpen: {
                1: true,
                2: true,
                3: true
            },
			filtersAndSorters: {
				title: "Filtrer l'actualité",
				actions: { submit: "Appliquer les filtrers" },
			},
		};
	},

	methods: {
		slugOrId(item, filter) {
			return item.id ? `${filter}-${item.id}` : item.slug;
		},
		onBeforeEnter(el) {
			el.style.height = "0";
		},

		onEnter(el) {
			el.style.height = el.scrollHeight + "px";
		},

		onBeforeLeave(el) {
			el.style.height = el.scrollHeight + "px";
		},

		onLeave(el) {
			el.style.height = "0";
		},

        toggle(index) {

            if(this.accordionOpen[index] === true ) {
                this.accordionOpen[index] = false 
            }
            else {
                this.accordionOpen[index] = true 
            }
        },

        choseBrand(el) {
            if(!this.filterSelected.Marques.includes(el)){  
                this.filterSelected.Marques.push(el); 
            }else{
                this.filterSelected.Marques.splice(this.filterSelected.Marques.indexOf(el), 1); 
            }
        },
        
        choseCorporate(el) {
            if(!this.filterSelected.Corporate.includes(el)){  
                this.filterSelected.Corporate.push(el); 
            }else{
                this.filterSelected.Corporate.splice(this.filterSelected.Corporate.indexOf(el), 1); 
            }
        },

        choseSectors(el) {
            if(!this.filterSelected.Secteurs.includes(el)){  
                this.filterSelected.Secteurs.push(el); 
            }else{
                this.filterSelected.Secteurs.splice(this.filterSelected.Secteurs.indexOf(el), 1); 
            }
        },
        applyFilters() {
            this.$emit('apply-filters', this.filterSelected);
            this.$emit('close-filters-modal');
        },
        resetFilters() {
            this.filterSelected = {Marques: [], Corporate: [], Secteurs: []}
            this.$emit('apply-filters', this.filterSelected);
            this.$emit('close-filters-modal');
        }
	},
};
</script>
