<template>
  <div class="container flex flex-col justify-end items-start h-full  pt-48 pb-10 lg:pb-48 relative"
			:style="'filter:blur('+blur+'); transform:translateY('+top+') scale('+scale+')'">
		<div class="bg-white text-dark text-sm rounded-full px-4 py-2 animate-pulse h-9 w-28">
		</div>
    <h2 class="text-white text-4xl mb-8 mt-8 w-full max-w-4xl">
			<div class="rounded-full bg-white bg-opacity-70 h-8 mb-2 animate-pulse"></div>
			<div class="rounded-full bg-white bg-opacity-70 h-8 mb-2 animate-pulse block sm:hidden"></div>
			<div class="rounded-full bg-white bg-opacity-70 h-8 w-1/3 animate-pulse"></div>
		</h2>
    <div class="flex text-white gap-4 text-xs items-center">
      <div class="animate-pulse rounded-xl bg-white bg-opacity-70 h-3 w-14"></div>
      <span class="w-0.5 h-0.5 bg-white rounded-full block"></span>
      <div class="animate-pulse rounded-xl bg-white bg-opacity-70 h-3 w-16"></div>
      <span class="w-0.5 h-0.5 bg-white rounded-full block"></span>
      <div class="animate-pulse rounded-xl bg-white bg-opacity-70 h-3 w-10"></div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
	props: ["article"],
	setup() {
		const top = ref(0);
		const blur = ref(0);
		const scale = ref(1);
		let pos = window.scrollY - 40;
    window.addEventListener('scroll', function() {
			pos = window.scrollY - 40;
			top.value = (window.scrollY/2)+'px'
			blur.value = (pos/20)+'px'
			scale.value = 1 - window.scrollY/5000
    })
		return {
			top,
			blur,
			scale,
		}
	},
};
</script>
