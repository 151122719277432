<template>
  <div class="min-h-full fixed z-50 bg-dark  flex top-0 left-0 w-full">

    <!-- <iframe v-if="article?.media_type == 1 && article?.acces_iframe && !showPdf"
            :src="article?.media_url"
            frameborder="0"
            class="w-full h-screen">
    </iframe> -->
    <!-- {{ article }} -->
    <article-detail v-if="article?.media_type == 1 || article?.media_type == 2 && pdfShow == null" :article="article" @show-source="ShowSource" />

    <div v-if="pdfShow && pdfShow.media_url == null" class="w-full h-screen pb-20 overflow-scroll">
      <pdf :source="article?.media_file?.url"
           @rendering-failed="handleRenderingFailed"
           @loading-failed="handleLoadingFailed"/>
    </div>

    <video controls v-else-if="article?.media_type == 3" class=" w-full mb-20">
      <source :src="article?.media_file?.url" :type="article?.media_file?.mime">
      Sorry, your browser doesn't support embedded videos.
    </video>

    <div v-else-if="article?.media_type == 4" class="w-full h-screen flex items-center justify-center">
      <audio controls :src="article?.media_file?.url" class="w-full mx-4">
        Your browser does not support the <code>audio</code> element.
      </audio>
    </div>
  </div>
</template>

<script>

import { ref } from "vue";
import pdf from 'vue-pdf-embed';
import ArticleDetail from "./ArticleDetail.vue";
import { mapState } from 'vuex'
import store from "@/store";

export default {
  components: {
    pdf,
    ArticleDetail
  },
  props: ['showPdf'],
  computed: {
    ...mapState('post', ['post']),
  },
  setup() {
    const article = ref(store.state.post.post);
    const error = ref(null);

    return {
      article,
      error,
    };
  },
  data() {
    return {
        pdfShow: null
    }
  },
  methods: {
    handleRenderingFailed(e) {
      console.log('handleRenderingFailed',e)
    },
    handleLoadingFailed(e) {
      console.log('handleLoadingFailed',e)
    },
    ShowSource(article) {
        this.pdfShow = article
    }
  },
  watch: {
    showPdf(newVal) {
      console.log('showPdf',newVal)
    }
  },
}
</script>

<style>
  .router-link-btn {
    @apply p-0 rounded-full w-8 h-8 flex items-center justify-center bg-dark bg-opacity-0 hover:bg-opacity-10;
  }

  .router-link-btn.small {
    @apply w-10 h-10;
  }
</style>
