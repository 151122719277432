<template>
  <div class="flex flex-col relative hover-square overflow-hidden
            bg-white bg-opacity-40 border border-white rounded-lg
              transition-colors duration-500 ratio-1/1 w-40 md:w-32
            dark:bg-black dark:bg-opacity-40 dark:border-black">
    <div class="absolute w-full h-full p-4 flex-grow flex flex-col justify-center items-center square z-10">
      <div class="flex-grow flex items-center justify-center">
        <div class="w-16 h-16 bg-white dark:bg-black animate-pulse rounded-lg transition-colors duration-500">

        </div>
      </div>
      <div class="rounded-xl bg-white dark:bg-black h-5 w-28 animate-pulse transition-colors duration-500"></div>
    </div>
  </div>
</template>
