import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import './styles/fonts.css'
import './styles/transitions.css'
import './styles/utilities.css'
import './styles/swiper.css'
import './styles/app.css'

import store from "./store";
import router from './router'

const app = createApp(App)

app.use(router).use(store)

app.mount('#app')
