<template>
	<div @click.prevent="showPost" class="container flex flex-col justify-end items-start lg:h-full pb-8 lg:pb-48 pt-48 pt-32 relative cursor-pointer remove-tap-highlight lg:px-10" :style="'filter:blur(' + blur + '); transform:translateY(' + top + ') scale(' + scale + ')'">
		<div class="bg-white text-dark text-sm rounded-full px-4 py-2">
			{{ article.brand?.front_label ? article.brand.front_label : article.brand?.name }}
		</div>
		<h2 class="text-white text-32 lg:text-4xl mb-6 mt-8 font-semibold max-w-4xl">{{ article.title }}</h2>
		<div class="flex text-white gap-4 text-xs items-center">
			<p>{{ article.source }}</p>
			<span class="w-0.5 h-0.5 bg-white rounded-full block"></span>
			<p>{{ type }}</p>
			<span class="w-0.5 h-0.5 bg-white rounded-full block"></span>
			<p>{{ date }}</p>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import { ref } from "vue";

import moment from "moment";

export default {
	props: ["article"],
	computed: {
		date() {
			// "2023-10-27 04:58:24"
			moment.locale("fr");
			return moment(this.article.publication_date, "YYYY-MM-DD hh:mm:ss").format("LL");
		},
		type() {
			const types = ["", "Web", "Print", "TV", "Radio"];
			return types[this.article.media_type];
		},
	},
	setup() {
		const top = ref(0);
		const blur = ref(0);
		const scale = ref(1);
		let pos = window.scrollY - 40;
		window.addEventListener("scroll", function () {
			pos = window.scrollY - 40;
			top.value = window.scrollY / 2 + "px";
			blur.value = pos / 20 + "px";
			scale.value = 1 - window.scrollY / 5000;
		});
		return {
			top,
			blur,
			scale,
		};
	},
	methods: {
		showPost() {
			this.$router.push({ name: this.$route.name, hash: "#post" });
			store.commit("post/show", this.article);
		},
	},
};
</script>
