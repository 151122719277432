<template>
  <button @click.stop.prevent="showNotification" class="router-link-btn remove-tap-highlight relative">
    <img src="/img/pictos/bell.svg" width="20"
         class="filter dark:invert transition-all duration-500"
         :class="mode == 'light' ? 'invert':'invert-0'">
    <transition>
      <span v-if="hasNotifications" class="flex h-2.5 w-2.5 absolute top-0.5 right-1.5">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green dark:bg-white transition-colors duration-500 opacity-75"></span>
        <span class="relative inline-flex rounded-full h-2.5 w-2.5 bg-green dark:bg-white transition-colors duration-500"></span>
      </span>
    </transition>
  </button>
</template>

<script>
import store from "@/store";

export default {
  props: ['mode'],
  computed: {
    hasNotifications() {
      return this.$store.state.notification.hasNotification
    },
  },
  methods: {
    showNotification() {
      store.commit("notification/showNotification");
    }
  },
}
</script>
