<template>
  <div class="min-h-full flex" @click.prevent.self="isOpen = false">
    <div class="container pb-20 pt-10 lg:pb-10 lg:pt-36 flex flex-col items-center min-h-full" @click.prevent.self="isOpen = false">

      <div class="lg:flex w-full items-center lg:justify-between lg:mt-6">
        <h1 class="text-center text-4xl mt-6 mb-10 sm:mt-8 sm:mb-14 md:mt-12 md:mb-20 lg:mt-0 lg:mb-10 dark:text-white transition-colors duration-500 font-semibold"
            @click.prevent.self="isOpen = false">
          Vos favoris
        </h1>
        <div @click.prevent="isOpen = !isOpen" class="max-w-sm mx-auto lg:mx-0 bg-white dark:bg-black dark:text-white transition-colors duration-500 flex items-center justify-between w-full mb-10 px-4 py-2.5 relative cursor-pointer">
          <p>{{ sorting ? sortingOrders[sorting] : 'Trier par' }}</p>
          <img src="/img/pictos/chevron-down.svg"
               class="transition-transform transform duration-300"
               :class="isOpen ? 'rotate-180' : ''">
          <transition name="dropdown" mode="in-out">
            <ul class="absolute top-full left-0 w-full flex flex-col gap-2.5 px-4 py-2.5 z-30
                    bg-white dark:bg-black dark:text-white transition-all duration-300 overflow-hidden"
                v-show="isOpen">
              <template v-for="(s,key) in sortingOrders" :key="key">
                <li @click.prevent="sorting = key" class="cursor-pointer">{{ s }}</li>
              </template>
            </ul>
          </transition>
        </div>
      </div>
      <div class="grid gap-4 lg:gap-6 xl:gap-10 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full" @click.prevent="isOpen = false">
        <CardPost v-for="article of articles" :key="article" :article="article"/>
        <template v-if="!articles && !error">
          <LoadingCardPost v-for="n of defaultFavNumber" :key="n"/>
        </template>
        <p v-if="error" class="mt-8 text-center dark:text-white transition-colors duration-500">
          {{ error }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CardPost from '@/components/CardPost.vue';
import LoadingCardPost from '@/components/loading/CardPost.vue';

import { onMounted, ref } from "vue";
import { useRoute } from 'vue-router';

import UserService from '@/services/user.service';

export default {
  components: {
    CardPost,
    LoadingCardPost
  },
  setup() {
    const articles = ref(null);
    const error = ref(null);
    const route = useRoute()
    const isOpen = ref(false)
    const sorting = ref()
    const sortingOrders = ref({})
    const defaultFavNumber = ref(localStorage.getItem('favorisCount') ? Number(localStorage.getItem('favorisCount')) : 6)

    sortingOrders.value = {
      'chrono' : 'Du plus récent au plus ancien',
      'antichrono' : 'Du plus ancien au plus récent',
      'AZ' : 'De A à Z',
      'ZA' : 'De Z à A',
    }

    const fetchLikedArticles = () => {
      UserService.getLiked(route.params.name || null)
        .then(res => {
          // a non-200 response code
          if (res.status !== 200) {
            const error = new Error(res.statusText);
            error.json = res;
            throw error.json.statusText;
          }
          articles.value = res.data.data;

          articles.value.forEach(article => {
            article.title_clean = article.title.replace(/([^\w]|_)/g, '')
          });
        })
        .catch(err => {
          error.value = err;
          if (err.json) {
            return err.json.then(json => {
              error.value.message = json.message;
            });
          }
        })
    }

    onMounted(() => {
      fetchLikedArticles();
    });

    return {
      articles,
      error,
      sorting,
      sortingOrders,
      isOpen,
      defaultFavNumber,
    };
  },
  watch: {
    sorting(val) {
      switch (val) {
        case 'chrono':
          this.articles.sort((a, b) => {
            if (a.publication_date > b.publication_date) { return -1; }
            if (a.publication_date < b.publication_date) { return 1; }
            return
          });
          break;
        case 'antichrono':
          this.articles.sort((a, b) => {
            if (a.publication_date < b.publication_date) { return -1; }
            if (a.publication_date > b.publication_date) { return 1; }
            return
          });
          break;
        case 'AZ':
          this.articles.sort((a, b) => {
            if (a.title_clean < b.title_clean) { return -1; }
            if (a.title_clean > b.title_clean) { return 1; }
            return
          });
          break;
        case 'ZA':
          this.articles.sort((a, b) => {
            if (a.title_clean > b.title_clean) { return -1; }
            if (a.title_clean < b.title_clean) { return 1; }
            return
          });
          break;
      }
    }
  },
}
</script>

