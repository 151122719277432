<template>
  <transition name="slideFromTop" mode="out-in">
    <div v-if="showNotifications" class="fixed dark:bg-black bg-white bg-opacity-70 dark:bg-opacity-70 backdrop-filter backdrop-blur-sm dark:text-white w-full top-0 left-0 z-50 text-center p-4">
      <p @click="hideNotification" class="underline hover:no-underline cursor-pointer text-sm">
        Dernière mise à jour le {{ lastUpdate }}
      </p>
    </div>
  </transition>
</template>

<script>
import store from "@/store";

export default {
  props: ['mode'],
  computed: {
    showNotifications() {
      return this.$store.state.notification.showNotif
    },
    lastUpdate() {
      return this.$store.state.notification.lastUpdate
    }
  },
  methods: {
    hideNotification() {
      store.commit("notification/hideNotification");
    }
  },
}
</script>
