export default function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));
  let base_header = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
  if (user && user.access_token) {
    return {
      base_header,
      'Authorization': 'Bearer ' + user.access_token,
    };
  } else {
    return { base_header };
  }
}
