<template>
  <div class="lg:hidden fixed w-full bottom-0 left-0 h-11 px-4 mb-4 z-50">
    <div class="bg-white border transition-colors duration-500
                text-dark dark:text-white border-white dark:border-dark bg-opacity-80 dark:bg-opacity-70 dark:bg-black
                backdrop-filter backdrop-blur-sm rounded-b-4xl rounded-t-md h-full w-full block overflow-hidden">
      <div class="container px-0 h-full">

        <template v-if="!article">
          <ul class="flex justify-around h-full">
            <template v-for="menuItem of menuItems" :key="menuItem">
              <li class="flex-1 lg:flex-auto flex">
                <router-link :to="{ name: menuItem.destination }" class="router-link remove-tap-highlight">
                  <div class="relative">
                    <img class="filter invert dark:invert-0 transition-all duration-500 h-5" :src="menuItem.icon"/>
                    <span v-if="menuItem.name == 'Favoris'"
                      class="pop-top absolute w-3 h-3 -top-2 left-4 bg-green text-white dark:bg-white dark:text-green
                            text-xs rounded-full flex items-center justify-center opacity-0 text-center leading-none">
                      <span>+</span>
                    </span>
                  </div>
                  <span class="hidden sm:block">
                    {{ menuItem.name }}
                  </span>
                </router-link>
              </li>
            </template>
          </ul>
        </template>

        <template v-else>
          <ul class="flex justify-between h-full items-center px-6">
            <li>
              <a href="#" @click.prevent="goBack()">
                <img src="/img/pictos/arrow.svg" class="filter dark:invert transition-all duration-500 transform -rotate-90"
                      height="20">
              </a>
            </li>
            <li v-if="showPdf">
              <button @click.prevent="toggleShowPdf(false)" class="text-sm">
                Revenir au site web
              </button>
            </li>
            <li class="flex gap-6">
              <BtnLike :article="article" :size="'big'"/>
              <button @click="share()" v-if="article.media_url !== null">
                <img src="/img/pictos/share2.svg" class="transition-all duration-500 transform filter dark:invert" height="20" />
            </button>
            </li>
          </ul>
        </template>


      </div>
    </div>
  </div>
</template>

<script>
import BtnLike from '@/components/BtnLike.vue'

import { mapState } from 'vuex'
import store from "@/store";

export default {
  name: 'Navigation',
  emits: ["toggleShowPdf"],
  components: {
    BtnLike
  },
  computed: {
    ...mapState('post', ['post']),
    article() {
      return this.$store.state.post.post;
    },
  },
  data() {
    return {
      showPdf: false,
      menuItems: [
        {
          'name': 'Accueil',
          'destination' : 'Home',
          'icon': '/img/pictos/house.svg',
        },
        {
          'name': 'Archives',
          'destination' : 'Archives',
          'icon': '/img/pictos/archives.svg',
        },
        {
          'name': 'Favoris',
          'destination' : 'Favoris',
          'icon': '/img/pictos/heart-big.svg',
        }
      ]
    };
  },
  methods: {
    toggleShowPdf(val) {
      this.showPdf = val;
      this.$emit("toggleShowPdf", val);
    },
    goBack() {
      if(this.showPdf == true) {
        this.toggleShowPdf(false)
      } else {
        store.commit('post/hide')
      }
    },
    async share() {
        if (navigator.share) {
            await navigator.share({
                title: this.article.title,
                url: this.article.media_url,
            });
            return;
        }
    },
  }
}
</script>

<style>
  .router-link {
    @apply items-center justify-center text-center text-xxs opacity-50;
  }

  @screen sm {
    .router-link {
      @apply text-sm;
    }
  }

  .router-link-btn {
    @apply opacity-100;
  }

</style>

<style scoped>
  .router-link:not(.router-link-active) {
    @apply hover:opacity-100;
  }

  .router-link.router-link-active {
    @apply opacity-100;
  }

  .router-link {
    @apply flex flex-col flex-grow px-4;
  }

  .router-link img {
    @apply sm:mb-2;
  }

  @screen sm {
    .router-link {
      @apply flex-row;
    }
    .router-link img {
      @apply mb-0 mr-2;
    }
  }

</style>
