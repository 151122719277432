<template>
  <div @click.prevent='scrollToTop'
       class="fixed z-20 bottom-20 lg:bottom-4 right-4 h-12 w-12
              bg-white ml-auto transform transition-all cursor-pointer rounded-lg
              dark:bg-black duration-500
              bg-opacity-80 dark:bg-opacity-70
              backdrop-filter backdrop-blur-sm
              remove-tap-highlight
              flex flex-col items-center justify-center"
       :class="isScrolled ? 'opacity-100 pointer-events-auto translate-y-0' : 'opacity-0 translate-y-8 pointer-events-none'"
       >
    <img src="/img/pictos/arrow.svg" class="filter dark:invert duration-500" width="16" height="18">
  </div>
</template>

<script>
import { ref } from "vue";

export default{
  setup() {
    const isScrolled = ref(false);
    window.addEventListener('scroll', function() {
      if(window.scrollY > window.innerHeight)
        isScrolled.value = true;
      else
        isScrolled.value = false;
    })
    return {
      isScrolled
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
}
</script>
