import axios from 'axios';
import authHeader from './auth-header';
class AuthService {
  login(user) {
    return axios
      .post(process.env.VUE_APP_API_URL + 'auth/login', {
        email: user.email,
        // password: user.password,
        remember_me: true
      },{ headers: authHeader() })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  logout() {
    localStorage.removeItem('user');
  }
  // register(user) {
  //   return axios.post(API_URL + 'signup', {
  //     email: user.email,
  //     password: user.password
  //   },{ headers: authHeader() });
  // }
  forgotPassword(email) {
    return axios.post(process.env.VUE_APP_API_URL + 'retrieve-password', {
      email: email,
    },{ headers: authHeader() });
  }
}
export default new AuthService();
