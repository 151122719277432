<template>
  <div class="h-full">
    <template v-if="holidays_mode == 1">
      <Holidays :message="holidays_message"/>
    </template>

    <template v-else>

      <MobileHeader v-if="currentUser" :head_top_news="head_top_news"/>

      <transition name="slideFromRight" mode="out-in">
        <SinglePost v-if="post" :showPdf="showPdf"/>
      </transition>

      <DesktopNavigation v-if="currentUser" @toggle-show-pdf="(val) => showPdf = val"/>

      <MobileNavigation v-if="currentUser" @toggle-show-pdf="(val) => showPdf = val"/>

      <router-view :head_top_news="head_top_news"></router-view>

      <ScrollToTop v-if="currentUser"/>

      <Notification/>

      <transition name="slideFromTop" mode="out-in">
        <div v-if="updateExists" class="fixed dark:bg-black bg-opacity-70 bg-white dark:bg-opacity-70 backdrop-filter backdrop-blur-sm dark:text-white w-full top-0 left-0 z-50 text-center p-4">
          <p @click="refreshApp" class="underline hover:no-underline cursor-pointer text-sm">
            Une nouvelle version de l'application est disponible. Cliquez ici pour la mettre à jour.
          </p>
        </div>
      </transition>
    </template>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from "axios";

import { initializeApp, getApps } from 'firebase/app';
import { getMessaging, onMessage, getToken, isSupported } from 'firebase/messaging'

import UserService from '@/services/user.service'

import MobileHeader from '@/components/MobileHeader.vue'
import DesktopNavigation from '@/components/DesktopNavigation.vue'
import MobileNavigation from '@/components/MobileNavigation.vue'
import Notification from '@/components/Notification.vue'
import ScrollToTop from '@/components/ScrollToTop.vue'
import SinglePost from '@/components/SinglePost.vue'

import update from '@/mixins/update'

import Holidays from '@/views/Holidays.vue'
export default {
  name: 'App',
  mixins: [update],
  components: {
    MobileNavigation,
    MobileHeader,
    Notification,
    DesktopNavigation,
    ScrollToTop,
    SinglePost,
    Holidays
  },
  computed: {
    ...mapState('post', ['post']),
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    currentUser(newUser) {
      if(newUser) {
        this.initFCM()
      }
    }
  },
  data() {
    return {
      showPdf: false,
      brand: null,
      holidays_mode: null,
      holidays_message: null,
      head_top_news: true,
    };
  },
  created() {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        let response = error.response;
        if(response.status == 401) {
          this.$store.dispatch("auth/logout").then(() => {
            this.$router.push({'name':'Login'})
          },(error) => {
            console.log('error logout :',error)
          })
          throw new axios.Cancel('access_token invalid. User logged out.');
        }
        return Promise.reject(error);
      }
    )
  },
  mounted() {
    if(this.currentUser) {
      this.initFCM()
    }
    this.getSettings();
    this.$store.dispatch('notification/getLastUpdate')
    this.$store.dispatch('favoris/getFavorisCount')
  },
  methods: {
    updateSingleBrand(brand) {
      this.brand = brand;
      },
    getSettings() {
      UserService.getSettings().then((response) => {
        this.holidays_mode = response.data.holidays_mode.value
        this.holidays_message = response.data.holidays_message.value
      })
    },
    initFCM() {
      const firebaseConfig = {
        apiKey: "AIzaSyBQC_qvz4uuOpxRU66nBbE_U5dKJRgSIOg",
        authDomain: "renault-app-news.firebaseapp.com",
        projectId: "renault-app-news",
        storageBucket: "renault-app-news.appspot.com",
        messagingSenderId: "804110226302",
        appId: "1:804110226302:web:c5b59f56631dccc3e7592f",
      };

      const apps = getApps()

      const app = !apps.length ? initializeApp(firebaseConfig) : apps[0]

      isSupported()
        .then((isSupported) => {
          if(isSupported) {
            const messaging = getMessaging(app);

            onMessage(messaging, (payload) => {
              console.log('Messaging on client: ', payload)
            })

            this.activateFCM(messaging);
          }
        })
    },
    async activateFCM(messaging) {
      const token = await getToken(messaging, {
        vapidKey: 'BJ30bYIcKgxWWtGC6AdTbbFhqcn-gNRRu73k2EFMu2nGS-KLvPaOuRcUblgUaSCNTAlkk5W4eJoeZjqG3b00HJ0'
      })
      if(token) {
        UserService.token(token)
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    }
  },

}
</script>
