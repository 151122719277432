<template>
  <div class="bg-cover bg-center bg-no-repeat flex items-center z-50 relative min-h-full" style="background-image:url('/img/R-DAM_1336987.jpg')">
    <div class="container px-2 py-8 mx-auto">
      <div class="bg-white rounded-md p-10 mx-auto max-w-sm popup text-center">
        <img src="img/rg-logo-vertical.svg" alt="Logo Renault Group" width="133" height="119" class="mx-auto mb-10"/>
        <span v-html="message" class="text-base"/>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';

export default {
    props: ['message'],
  setup() {
    const user = reactive({
      email: null,
      // password: "zUSMkWzxYGw3RD3#",
    });
    const loading = ref(false);
    const error = ref(null);

    return {
      user,
      loading,
      error,
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$store.dispatch("auth/login", this.user).then(
        () => {
          this.$router.push(this.$route.query.redirect || '/');
        },
        (error) => {
          this.loading = false;
          this.error =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  }
}
</script>

<style>

</style>
